import React, { Component } from "react";
import { commonPostAPI, commonUploadPostAPI } from "../../commoncall/commoncall";
import { Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { withRouter } from "../withRouter";
import Custombreadcrumb from "../Custombreadcrumb";
import { Button, Grid } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Typography from '@mui/material/Typography';

var partialState = {
  showColumnsLength: 0,
};
class CrmimportData extends Component {
  constructor(props) {
    super(props);
    this.setState(partialState);
    this.state = {
      errors: {
        fileName: "Please upload file.",
        template_uuid: "Please select CRM Template",
        skipRow: "",
      },
      templateList: [],
      template_uuid: "",
      fileName: "",
      importFile: "",
      selectedFile: "",
      steps: ['Upload File', 'Column Mapping', 'Results'],
      activeStep: 0,
      completed: 2,
      importType: 0,
      skipRow: 1,
      columnItem: [],
      showColumns: [],
      showColumnsLength: 0,
      onlyColumnValue: [],
      finalResult: {},
      hierarchy: [
        {
          label: "Home",
          action: "",
        },
        {
          label: "CRM Data",
          action: `reviewlist`,
        },
        {
          label: "Import CRM Data",
          action: ``,
        }
      ],
    };
  }

  clearColumnState = (myState) => {
    let keys = (myState) ? Object.keys(myState) : {};
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (key.substring(0, 7) === 'column_') {
        partialState[key] = "0";
      }
    }
    this.setState({ ...partialState });
  }

  componentWillMount = async () => {
    let response = await commonPostAPI("templatelist", { domain_uuid: sessionStorage.getItem('domain_uuid') });
    this.setState({ templateList: response });
  }

  totalSteps = () => {
    return this.state.steps.length;
  };

  setActiveStep = (value) => {
    this.setState({ activeStep: value })
  }

  setCompleted = (value) => {
    this.setState({ completed: value })
  }

  completedSteps = () => {
    return Object.keys(this.state.completed).length;
  };

  isLastStep = () => {
    return this.state.activeStep === this.totalSteps() - 1;
  }

  handleNext = async () => {
    const newActiveStep =
      this.isLastStep()
        ? this.state.steps.findIndex((step, i) => !(i in this.state.completed))
        : this.state.activeStep + 1;

    if (newActiveStep === 1) {
      this.clearColumnState(this.state);
      let extension = this.state.fileName.split('.').pop();
      if (extension === '') {
        return true;
      }
      const fileFileType = ["xlsx", "xls", "XLSX", "XLS", "csv", "CSV"];
      var data = fileFileType.filter((val) =>
        val.includes(extension)
      );

      if (data.length === 0) {
        this.setState({ alertMessage: 'Allow only xlsx or xls file...' });
        return true;
      } else {
        var formData = new FormData();
        formData.append("file", this.state.selectedFile);
        formData.append("skipRow", this.state.skipRow);
        formData.append("filename", this.state.selectedFile.name);
        formData.append("domain_uuid", sessionStorage.getItem("domain_uuid"));
        let respones = await commonUploadPostAPI("uploadcrmdatafile", formData);

        if (respones && respones.rows) {
          this.setState({ showColumns: respones.rows, showColumnsLength: respones.rows.length });
        }

        if (respones && respones.file) {
          this.setState({ importFile: respones.file, });
        }
      }
    }
    if (newActiveStep === 2) {

      let flag = 0;//this.check_duplicate_in_array(this.state.onlyColumnValue);
      if (flag.length > 0) {
        this.setState({ alertMessage: 'Duplicate column selection not allowd.' });
        return false;
      } else {
        var formData = new FormData();
        formData.append("importFile", this.state.importFile);
        formData.append("skipRow", this.state.skipRow);
        formData.append("domain_uuid", sessionStorage.getItem("domain_uuid"));
        formData.append("template_uuid", this.state.template_uuid);
        formData.append("user_uuid", sessionStorage.getItem('user_uuid'));
        formData.append("showColumnsLength", this.state.showColumnsLength);
        formData.append("importtype", this.state.importType);
        const keys = Object.keys(this.state);

        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          if (key.substring(0, 7) === 'column_' && this.state[key] !== "" && this.state[key] !== "-1") {
            formData.append(`${key}`, this.state[key]);
          }
        }
        let respones = await commonPostAPI("importcrmdatafile", formData);
        this.setState({ finalResult: 'Import successfully' });
        this.handleStep(2);
      }
    }
    this.setState({ activeStep: newActiveStep });

  };

  check_duplicate_in_array = ((input_array) => {
    const duplicates = input_array.filter((item, index) => input_array.indexOf(item) !== index);
    console.log(duplicates);
    return Array.from(new Set(duplicates));
  })

  handleBack = () => {
    let newValue = this.state.activeStep - 1;
    this.setState({ activeStep: newValue });

  };

  handleStep = (step) => () => {
    this.setState({ activeStep: step });
  };

  onOpenAction = (action) => {
    this.props.navigate(`/${action}`);
  };

  onChangeItem = (e) => {
    e.preventDefault();
    if (e.target.name === 'fileName') {
      partialState['selectedFile'] = e.target.files[0];
    }
    partialState[e.target.name] = e.target.value;
    let errors = this.state.errors;
    if (e.target.name === 'template_uuid') {
      this.changeColumnSelectionItems(e.target.value);
    }
    switch (e.target.name) {
      case "fileName":
        errors.fileName =
          e.target.value.length < 1 ? "Please upload CRM Data file.." : "";
        break;
      case "skipRow":
        errors.skipRow =
          e.target.value.length < 1 ? "Please enter skip number." : "";
        break;
      case "template_uuid":
        errors.template_uuid =
          e.target.value.length < 1 ? "Please select CRM Template." : "";
        break;
      default:
        break;
    }
    let column = e.target.name.substring(0, 7);
    if (column === "column_" && e.target.value !== "0" && e.target.value !== "") {
      let oldValue = this.state.onlyColumnValue;
      oldValue.push(e.target.value);
      this.setState({ onlyColumnValue: oldValue });
    }

    this.setState({ errors, [e.target.name]: e.target.value });
    this.setState(partialState);
  };

  changeColumnSelectionItems = async (template_uuid) => {
    let response = await commonPostAPI("getTemplateListItems", { template_uuid: template_uuid });
    this.setState({ columnItem: response });
  }

  render() {
    if (sessionStorage.length === 0) {
      return <Navigate to="/" replace={true} />;
    }
    const theme = createTheme();

    let {
      errors,
      alertMessage,
      steps,
      activeStep,
      completed,
      skipRow,
      columnItem,
      showColumns,
      finalResult,
      templateList,
      template_uuid
    } = this.state;


    let secondRow = "";
    let name = "";
    let sr = 0;
    if (activeStep === 1) {
      secondRow = (showColumns.length > 0) ? showColumns.map((items, index) => {
        sr = index + 1;
        name = `column_${sr}`;
        return <Grid container spacing={5} xs={12} ml="5" style={{ borderBottom: '1px' }}>
          <Grid item md={1}>({sr})</Grid>
          <Grid item md={4}>{items}</Grid>
          <Grid item md={7}>

            <Select
              key={index}
              fullWidth
              id={name}
              name={name}
              onChange={this.onChangeItem}
              value={this.state[name] !== undefined ? this.state[name] : "-1"}
              autoComplete="none"
            >
              <MenuItem value="-1">Select</MenuItem>
              <MenuItem value="0">Phone Number</MenuItem>
              {
                (columnItem.length > 0) ? columnItem.map((item) => (
                  <MenuItem value={item.template_list_uuid}>{item.tools_label}</MenuItem>
                )) : []
              }
            </Select>
          </Grid>
        </Grid>;
      })
        : '';
    }

    return (
      <div className="submit-form formElements" >
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xl">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "15px 0",
              }}
            >
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Custombreadcrumb hierarchy={this.state.hierarchy} />
                </Grid>
              </Grid>
              <Box className="formBox">
                <Box
                  component="form"
                  name="myform"
                  noValidate
                  sx={{ mt: 2, width: "100%", padding: "0 15px" }}
                >


                  <Grid container spacing={5} xs={12}>
                    <Grid item md={12}>
                      <Stepper nonLinear activeStep={activeStep}>
                        {steps.map((label, index) => (
                          <Step key={label} completed={completed[index]}>
                            <StepButton color="inherit" onClick={this.handleStep(index)}>
                              {label}
                            </StepButton>
                          </Step>
                        ))}
                      </Stepper>
                      <div>
                        {
                          <React.Fragment>
                            {(activeStep === 0) ?
                              <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                                <Grid container spacing={5} xs={12}>
                                  <Grid item md={3}>
                                    <label>
                                      CRM Template
                                    </label>
                                    <Select
                                      fullWidth
                                      labelId="template_uuid"
                                      id="template_uuid"
                                      value={template_uuid}
                                      label="template_uuid"
                                      name="template_uuid"
                                      onChange={this.onChangeItem}
                                      size="small"
                                      autoComplete="none"
                                    >
                                      <MenuItem value="">Select</MenuItem>
                                      {templateList.map((item) => (
                                        <MenuItem value={item.value}>{item.label}</MenuItem>
                                      ))}
                                    </Select>
                                    {errors.template_uuid.length > 0 ? (
                                      <Stack className="error-show" spacing={2}>
                                        {errors.template_uuid}
                                      </Stack>
                                    ) : (
                                      ""
                                    )}
                                  </Grid>
                                </Grid>
                                <Grid container spacing={5} xs={12}>
                                  <Grid item md={3}>
                                    <label>Skip Row</label>
                                    <TextField
                                      margin="normal"
                                      fullWidth
                                      id="skipRow"
                                      name="skipRow"
                                      value={skipRow ?? 0}
                                      onChange={this.onChangeItem}
                                      autoComplete="none"
                                      autoFocus
                                      type="number"
                                      size="small"
                                    />
                                    {errors.skipRow.length > 0 ? (
                                      <Stack className="error-show" spacing={2}>
                                        {errors.skipRow}
                                      </Stack>
                                    ) : (
                                      ""
                                    )}
                                  </Grid>
                                </Grid>
                                <Grid container spacing={5} xs={12}>
                                  <Grid item md={12}>
                                    Upload : <TextField
                                      margin="normal"
                                      required
                                      fullWidth
                                      id="fileName"
                                      name="fileName"
                                      onChange={this.onChangeItem}
                                      autoComplete="none"
                                      autoFocus
                                      type="file"
                                      size="small"
                                    />
                                    {errors.fileName.length > 0 ? (
                                      <Stack className="error-show" spacing={2}>
                                        {errors.fileName}
                                      </Stack>
                                    ) : (
                                      ""
                                    )}
                                    {alertMessage ? (
                                      <Stack className="error-show" spacing={2}>
                                        {alertMessage}
                                      </Stack>
                                    ) : (
                                      ""
                                    )}
                                  </Grid>
                                </Grid>
                              </Typography> : ""}

                            {(activeStep === 1) ?
                              <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                                <Grid container spacing={5} xs={12} style={{ borderBottom: 1 }}>
                                  <Grid item md={1}><b>Sr.No</b></Grid>
                                  <Grid item md={4}><b>Sheet Start Row</b></Grid>
                                  <Grid item md={7}><b>Column Selection</b></Grid>
                                </Grid>
                                {secondRow}
                                {alertMessage ? (
                                  <Stack className="error-show" spacing={2}>
                                    {alertMessage}
                                  </Stack>
                                ) : (
                                  ""
                                )}
                              </Typography> : ""}

                            {(activeStep === 2) ?
                              <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                                <Grid container spacing={5} xs={12}>
                                  <Grid item md={3}>
                                    <label>Status : </label>
                                  </Grid>
                                  <Grid item md={3}>
                                    <label>{finalResult}</label>
                                  </Grid>
                                </Grid>
                              </Typography> : ""}

                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                              {/* <Button
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={this.handleBack}
                                sx={{ mr: 1 }}
                              >
                                Back
                              </Button> */}
                              <Box sx={{ flex: '1 1 auto' }} />
                              {
                                activeStep !== steps.length - 1 ?
                                  <Button onClick={this.handleNext} sx={{ mr: 1 }}>
                                    Next
                                  </Button> : ""
                              }
                              {activeStep !== steps.length &&
                                (completed[activeStep] ? (
                                  <Typography variant="caption" sx={{ display: 'inline-block' }}>
                                    Step {activeStep + 1} already completed
                                  </Typography>
                                ) : "")}
                            </Box>
                          </React.Fragment>
                        }
                      </div>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} justifyContent="flex-end" container>
                    <table className="form-btns">
                      <tr>
                        <td>
                          <Button
                            type="button"
                            fullWidth
                            className="btn btn-outline-white"
                            sx={{
                              mt: 1,
                              mb: 1,
                              height: "35px",
                              padding: "5px 20px",
                              marginLeft: "10px",
                            }}
                            size="small"
                            onClick={() => this.onOpenAction(`reviewlist`)}
                          >
                            Back
                          </Button>
                        </td>
                      </tr>
                    </table>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      </div >
    );
  }
}

export default withRouter(CrmimportData);
