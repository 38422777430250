import Private from './components/Private';
import Login from './components/Login'
import Dashboard from './components/Dashboard'
// User 
import UserData from './components/User/UserData'
import UseraddData from './components/User/UseraddData'
import UsereditData from './components/User/UsereditData'
// Ring Group
import RinggroupData from './components/Ringgroup/RinggroupData'
import RinggroupaddData from './components/Ringgroup/RinggroupaddData'
import RinggroupeditData from './components/Ringgroup/RinggroupeditData'
// Destination
import DestinationsData from './components/Destination/DestinationsData'
import DestinationsaddData from './components/Destination/DestinationsaddData'
import DestinationseditData from './components/Destination/DestinationseditData'
// Call Center
import CallcenterData from './components/Callcenter/CallcenterData'
import CallcenteraddData from './components/Callcenter/CallcenteraddData'
import CallcentereditData from './components/Callcenter/CallcentereditData'
// IVR Menus
import IvrmenuData from './components/Ivrmenu/IvrmenuData'
import IvrmenuaddData from './components/Ivrmenu/IvrmenuaddData'
import IvrmenueditData from './components/Ivrmenu/IvrmenueditData'
// Recording
import RecordingData from './components/Recording/RecordingData'
import RecordingaddData from './components/Recording/RecordingaddData'
// DID
import DidData from './components/Did/DidData';
import DidaddData from './components/Did/DidaddData';
import DideditData from './components/Did/DideditData';

// Monitoring
import Activecall from './components/Activecall/ActivecallData';
import CallcentercallData from './components/Callcentercall/CallcentercallData';

// Voicemail
import VoicemailData from './components/Voicemail/VoicemailData';
import Voicemailmessages from './components/Voicemail/Voicemailmessages';

// Reports
import CallsReport from './components/Report/CallsReport';
import QueuecallsReport from './components/Report/QueuecallsReport';
import QueuestatisticsReport from './components/Report/QueuestatisticsReport';
import AgentstatisticsReport from './components/Report/AgentstatisticsReport';
import ExtensionstatisticsReport from './components/Report/ExtensionstatisticsReport';
import TimestatisticsReport from './components/Report/TimestatisticsReport';
import Disporeport from './components/Report/Disporeport';
import Agentaccessaction from './components/Report/Agentaccessaction';

//Route Timing
import TimingeditData from './components/Timing/TimingeditData';

//Conference
import ConferenceData from './components/Conference/ConferenceData';
import ConferenceaddData from './components/Conference/ConferenceaddData';
import ConferenceviewData from './components/Conference/ConferenceviewData';

// crm template
import TemplateData from './components/Template/TemplateData';
import TemplateaddData from './components/Template/TemplateaddData';
import TemplateeditData from './components/Template/TemplateeditData';

// crm review form 
import ReviewsformData from './components/Reviews/ReviewsformData';
import ReviewsData from './components/Reviews/ReviewsData';
import CrmimportData from './components/Reviews/CrmimportData';
import Chat from "./components/UserChat/Chat";
import {
  createBrowserRouter, RouterProvider
} from "react-router-dom";
import { UserChatControllerProvider } from "./components/Context/UserChatContext";

// dispo
import DispoData from './components/Dispo/DispoData';
import DispoAdd from './components/Dispo/DispoaddData';
import DispoeditData from './components/Dispo/DispoeditData';
import DispogroupData from './components/Dispogroup/DispogroupData';
import DispogroupaddData from './components/Dispogroup/DispogroupaddData';
import DispogroupeditData from './components/Dispogroup/DispogroupeditData';

// Campaign
import CampaignData from './components/Campaign/CampaignData';
import CampaignaddData from './components/Campaign/CampaignaddData';
import CampaigneditData from './components/Campaign/CampaigneditData';

// Campaign List
import CampaignleadData from './components/Campaignlead/CampaignleadData';
import CampaignleadaddData from './components/Campaignlead/CampaignleadaddData';
import CampaignleadeditData from './components/Campaignlead/CampaignleadeditData';
import Campaignlistview from './components/Campaignlead/Campaignlistview';

// Campaign Dial lead
import DialleadData from './components/Diallead/DialleadData';
import DialleadImportData from './components/Diallead/DialleadImportData';

// Rate plan
import RateplanData from './components/Rateplan/RateplanData';
import RateplanaddData from './components/Rateplan/RateplanaddData';
import RateplaneditData from './components/Rateplan/RateplaneditData';

//Rates 
import RatesData from './components/Rates/RatesData';
import RatesaddData from './components/Rates/RatesaddData';
import RateeditData from './components/Rates/RateeditData';
import RateimportData from './components/Rates/RatesimportData';

//Billing
import BillingData from './components/Billing/BillingData';
import BillingeditData from './components/Billing/BillingeditData';

//Tranctions
import TransactionData from './components/Transaction/TransactionData';
import TransactionaddData from './components/Transaction/TransactionaddData';

//Domain
import DomainData from './components/Domain/DomainData';
import DomainaddData from './components/Domain/DomainaddData';

//Department
import DepartmentData from './components/Department/DepartmentData';
import DepartmentaddData from './components/Department/DepartmentaddData';
import DepartmenteditData from './components/Department/DepartmenteditData';

const routesConfig = [
  {
    path: "/",
    element: <Login />,
  },
  {
    element: <Private />,
    children: [
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/user",
        element: <UserData />,
      },
      {
        path: "/useradd",
        element: <UseraddData />,
      },
      {
        path: "/useredit/:id",
        element: <UsereditData />,
      },
      {
        path: "/ringgroup",
        element: <RinggroupData />,
      },
      {
        path: "/ringgroupadd",
        element: <RinggroupaddData />,
      },
      {
        path: "/ringgroupedit/:id",
        element: <RinggroupeditData />,
      },
      {
        path: "/destinations",
        element: <DestinationsData />,
      },
      {
        path: "/destinationsadd",
        element: <DestinationsaddData />,
      },
      {
        path: "/destinationsedit/:id",
        element: <DestinationseditData />,
      },
      {
        path: "/callcenter",
        element: <CallcenterData />,
      },
      {
        path: "/callcenteradd",
        element: <CallcenteraddData />,
      },
      {
        path: "/callcenteredit/:id",
        element: <CallcentereditData />,
      },
      {
        path: "/ivrmenu",
        element: <IvrmenuData />,
      },
      {
        path: "/ivrmenuadd",
        element: <IvrmenuaddData />,
      },
      {
        path: "/ivrmenuedit/:id",
        element: <IvrmenueditData />,
      },
      {
        path: "/recording",
        element: <RecordingData />,
      },
      {
        path: "/recordingadd",
        element: <RecordingaddData />,
      },
      {
        path: "/did",
        element: <DidData />,
      },
      {
        path: "/didadd",
        element: <DidaddData />,
      },
      {
        path: "/didedit/:id",
        element: <DideditData />,
      },
      {
        path: "/activecall",
        element: <Activecall />,
      },
      {
        path: "/callcentercall",
        element: <CallcentercallData />,
      },
      {
        path: "/voicemail",
        element: <VoicemailData />,
      },
      {
        path: "/voicemailmessages/:id",
        element: <Voicemailmessages />,
      },
      {
        path: "/calls",
        element: <CallsReport />,
      },
      {
        path: "/queuecalls",
        element: <QueuecallsReport />,
      },
      {
        path: "/queuestatistics",
        element: <QueuestatisticsReport />,
      },
      {
        path: "/timing",
        element: <TimingeditData />,
      },
      {
        path: "/conference",
        element: <ConferenceData />,
      },
      {
        path: "/conferenceadd",
        element: <ConferenceaddData />,
      },
      {
        path: "/conferenceview/:id",
        element: <ConferenceviewData />,
      },
      {
        path: "/agentstatistics",
        element: <AgentstatisticsReport />,
      },
      {
        path: "/extensionstatistics",
        element: <ExtensionstatisticsReport />,
      },
      {
        path: "/timestatistics",
        element: <TimestatisticsReport />,
      },
      {
        path: "/crmtemplate",
        element: <TemplateData />,
      },
      {
        path: "/crmtemplateadd",
        element: <TemplateaddData />,
      },
      {
        path: "/crmtemplateedit/:id",
        element: <TemplateeditData />,
      },
      {
        path: "/review/:id",
        element: <ReviewsformData />,
      },
      {
        path: "/reviewlist",
        element: <ReviewsData />,
      },
      {
        path: "/CrmimportData",
        element: <CrmimportData />,
      },
      {
        path: "/chat",
        element: <Chat />,
      },
      {
        path: "/dispo",
        element: <DispoData />,
      },
      {
        path: "/dispoadd",
        element: <DispoAdd />,
      },
      {
        path: "/dispoedit/:id",
        element: <DispoeditData />,
      },
      {
        path: "/dispogroup",
        element: <DispogroupData />,
      },
      {
        path: "/dispogroupadd",
        element: <DispogroupaddData />,
      },
      {
        path: "/dispogroupedit/:id",
        element: <DispogroupeditData />,
      },
      {
        path: "/campaignlist",
        element: <CampaignData />,
      },
      {
        path: "/addcampaign",
        element: <CampaignaddData />,
      },
      {
        path: "/campaignedit/:id",
        element: <CampaigneditData />,
      },
      {
        path: "/campaignlead",
        element: <CampaignleadData />
      },
      {
        path: "/addcampaignlead",
        element: <CampaignleadaddData />,
      },
      {
        path: "/campaignleadedit/:id",
        element: <CampaignleadeditData />,
      },
      {
        path: "/diallead/:id",
        element: <DialleadData />,
      },
      {
        path: "/dialleadimport/:id",
        element: <DialleadImportData />,
      },
      {
        path: "/campaignlistview/:id",
        element: <Campaignlistview />,
      },
      {
        path: "/disporeport",
        element: <Disporeport />,
      },
      {
        path: "/agentaccessaction",
        element: <Agentaccessaction />,
      },
      {
        path: "/rateplan",
        element: <RateplanData />,
      },
      {
        path: "/rateplanadd",
        element: <RateplanaddData />,
      },
      {
        path: "/rateplanedit/:id",
        element: <RateplaneditData />,
      },
      {
        path: "/rates/:id",
        element: <RatesData />,
      },
      {
        path: "/addrates/:id",
        element: <RatesaddData />,
      },
      {
        path: "/editrates/:id",
        element: <RateeditData />,
      },
      {
        path: "/ratesimport/:id",
        element: <RateimportData />,
      },
      {
        path: "/billings",
        element: <BillingData />,
      },
      {
        path: "/billingsedit/:id",
        element: <BillingeditData />,
      },
      {
        path: "/Transaction",
        element: <TransactionData />,
      },
      {
        path: "/transactionadd",
        element: <TransactionaddData />,
      },
      {
        path: "/domains",
        element: <DomainData />,
      },
      {
        path: "/domainsadd",
        element: <DomainaddData />,
      },
      {
        path: "/departments",
        element: <DepartmentData />,
      },
      {
        path: "/departmentadd",
        element: <DepartmentaddData />,
      },
      {
        path: "/departmentedit/:id",
        element: <DepartmenteditData />,
      },
    ],
  },
];

const router = createBrowserRouter(routesConfig);

const App = () => {
  return (
    <UserChatControllerProvider>
      <RouterProvider router={router} />
    </UserChatControllerProvider>
  );
};

export default App;

