import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import { commonPostAPI, commonDelAPI } from "../../commoncall/commoncall";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Typography,
  Box,
  TextField,
  Grid,
} from "@mui/material";
import "../../Assets/index.css";
import AG_GRID_LOCALE_EN from "../../language/locale.en.js";
import { Navigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import RemoveIcon from "@mui/icons-material/Remove";
import ReplayIcon from "@mui/icons-material/Replay";
import Container from "@mui/material/Container";
import Custombreadcrumb from "../Custombreadcrumb";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { withRouter } from "../withRouter";
import ReviewsformData from "../Reviews/ReviewsformData";
import AttachFileIcon from '@mui/icons-material/AttachFile';

var partialState = {};
var gridApiObj;
class TemplateData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      properties: {
        open: false,
        columnDefs: [
          {
            headerName: "Sr.No",
            valueGetter: "node.rowIndex + 1",
            filter: false,
            editable: false,
            headerCheckboxSelection: true,
            checkboxSelection: true,
            showDisabledCheckboxes: true,
            minWidth: 200,
          },
          {
            field: "template_name",
            headerName: "Name",
            filter: "agTextColumnFilter",
            tooltipField: "Name",
            cellStyle: { color: "blue" },
            editable: false,
            minWidth: 200,
          },
          {
            field: "form_title",
            headerName: "Title",
            filter: "agTextColumnFilter",
            tooltipField: "title",
            editable: false,
            minWidth: 200,
          },
          {
            field: "template_uuid",
            headerName: "Format",
            filter: "agTextColumnFilter",
            tooltipField: "Format",
            editable: false,
            cellRenderer: this.CustomButtonComponent,
            minWidth: 200,
          },
        ],
        rowModelType: "serverSide",
        serverSideStoreType: "full",
        enableBrowserTooltips: true,
      },
      template_uuid: '',
      paginationPageSize: 50,
      currentPage: 1,
      operationActionText: "",
      open: false,
      operationTitle: "",
      operationAction: "",
      isDelete: false,
      hierarchy: [
        {
          label: "Home",
          action: "",
        },
        {
          label: "CRM Template",
          action: "crmtemplate",
        },
      ],
      name: '',
      reviewOpen: false,
    };

    this.rowSelectionObj = [];
  }

  createServerSideDatasource = (server) => {
    return {
      getRows: (params) => {
        var response = server.getData(params.request);
        setTimeout(function () {
          if (response.success) {
            params.success({
              rowData: response.rows,
              rowCount: response.lastRow,
            });
          } else {
            params.fail();
          }
        }, 1000);
      },
    };
  };

  createFakeServer = (allData) => {
    return {
      getData: (request) => {
        var rowsForBlock = allData.slice(request.startRow, request.endRow);
        var lastRow = this.getLastRowIndex(request, rowsForBlock);
        return {
          success: true,
          rows: rowsForBlock,
          lastRow: lastRow,
        };
      },
    };
  };

  getLastRowIndex = (request, results) => {
    if (!results) return undefined;
    var currentLastRow = (request.startRow || 0) + results.length;
    return currentLastRow < (request.endRow || 0) ? currentLastRow : undefined;
  };

  onGridReady = async (params) => {
    gridApiObj = params.api;
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    let pageObj = {
      currentPage: 1,
      paginationPageSize: this.state.paginationPageSize,
      name: this.state.name,
      domain_uuid: sessionStorage.getItem('domain_uuid'),
    };
    const updateData = (data) => {
      var fakeServer = this.createFakeServer(data);
      var datasource = this.createServerSideDatasource(fakeServer);
      params.api.setServerSideDatasource(datasource);
    };

    let response = await commonPostAPI("templates", pageObj);
    updateData(response);
  };

  getRecord = async (e) => {
    let pageObj = {
      currentPage: 1,
      paginationPageSize: this.state.paginationPageSize,
      name: this.state.name,
      domain_uuid: sessionStorage.getItem('domain_uuid'),
    };
    this.rowSelectionObj = {};
    let response = await commonPostAPI("templates", pageObj);
    const updateData = (data) => {
      var fakeServer = this.createFakeServer(data);
      var datasource = this.createServerSideDatasource(fakeServer);
      gridApiObj.setServerSideDatasource(datasource);
    };
    updateData(response);
  };

  getRefresh = async (e) => {
    let pageObj = {
      currentPage: 1,
      paginationPageSize: this.state.paginationPageSize,
      name: this.state.name,
      domain_uuid: sessionStorage.getItem('domain_uuid'),
    };
    this.rowSelectionObj = {};
    let response = await commonPostAPI("templates", pageObj);
    const updateData = (data) => {
      var fakeServer = this.createFakeServer(data);
      var datasource = this.createServerSideDatasource(fakeServer);
      gridApiObj.setServerSideDatasource(datasource);
    };
    updateData(response);
  };

  reSet = () => {
    var partialState = {
      name: '',
    };
    this.setState(partialState);
  }

  onChangeItem = (e) => {
    e.preventDefault();
    partialState[e.target.name] = e.target.value;
    this.setState(partialState);
  }

  onOpenAction = (action) => {
    this.props.navigate(`/${action}`);
  };

  onSelectionChanged = (params) => {
    const selectedRows = params.api.getSelectedRows();
    this.rowSelectionObj = selectedRows;
  };

  onEditRow = async () => {
    if (typeof this.rowSelectionObj === "undefined") {
      this.setState({
        operationTitle: "Row Selection!",
        operationAction: "Please select record",
      });
      this.handleClickOpen();
      return true;
    } else {
      var count = Object.keys(this.rowSelectionObj).length;
      if (count === 0) {
        this.setState({
          operationTitle: "Row Selection!",
          operationAction: "Please select record!",
        });
        this.handleClickOpen();
        return true;
      } else if (count > 1) {
        this.setState({
          operationTitle: "Row Selection!",
          operationAction: "Please select single record only",
        });
        this.handleClickOpen();
        return true;
      }
    }
    var getID = this.rowSelectionObj.map((row) => {
      return row.template_uuid;
    });
    let id = getID.join();
    this.rowSelectionObj = [];
    this.onOpenAction(`crmtemplateedit/${id}`);
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false, operationTitle: "", operationAction: "" });
  };

  reviewClickOpen = () => {
    if (typeof this.rowSelectionObj === "undefined") {
      this.setState({
        operationTitle: "Row Selection!",
        operationAction: "Please select record",
      });
      this.handleClickOpen();
      return true;
    } else {
      var count = Object.keys(this.rowSelectionObj).length;
      if (count === 0) {
        this.setState({
          operationTitle: "Row Selection!",
          operationAction: "Please select record!",
        });
        this.handleClickOpen();
        return true;
      } else if (count > 1) {
        this.setState({
          operationTitle: "Row Selection!",
          operationAction: "Please select single record only",
        });
        this.handleClickOpen();
        return true;
      }
    }
    var getID = this.rowSelectionObj.map((row) => {
      return row.template_uuid;
    });
    let id = getID.join();
    this.setState({
      template_uuid: id,
      reviewOpen: true,
    });
  };

  reviewClickClose = () => {
    this.setState({ reviewOpen: false, operationTitle: "", operationAction: "" });
  };

  onRemoveSelectedConfirm = async () => {
    let response = await commonDelAPI(
      "deleteRecordTemplateByID",
      this.rowSelectionObj
    );
    this.handleClose();
    this.getRefresh();
    return response;
  };

  onRemoveSelected = () => {
    if (typeof this.rowSelectionObj === "undefined") {
      this.setState({
        operationTitle: "Row Selection!",
        operationAction: "Please select record",
      });
      this.handleClickOpen();
      return true;
    } else {
      var count = Object.keys(this.rowSelectionObj).length;
      if (count === 0) {
        this.setState({
          operationTitle: "Row Selection!",
          operationAction: "Please select record!",
        });
        this.handleClickOpen();
        return true;
      } else {
        this.setState({
          operationTitle: "Delete Selection!",
          operationAction: "Want to delete?",
          isDelete: true,
        });
        this.handleClickOpen();
        return true;
      }
    }
  };

  downloadFile = async (items) => {
    let response = await commonPostAPI("filetemplate", { id: items.template_uuid });
    if (response.filename && response.filename !== "") {
      const link = document.createElement("a");
      link.href = `${response.filename}`;
      link.download = response.filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  CustomButtonComponent = (props) => {
    let row = props.data;
    return <AttachFileIcon sx={{ paddingTop: 0.5 }} onClick={() => this.downloadFile(row)} />;
  };

  render() {
    let gridOptions = {
      defaultColDef: {
        flex: 1,
        sortable: true,
        filter: true,
        resizable: true,
        autoHeight: false,
        editable: true,
        floatingFilter: false,
      },
      localeText: AG_GRID_LOCALE_EN,
      columnDefs: this.state.properties.columnDefs,
      cacheBlockSize: this.state.paginationPageSize,
      rowBuffer: 0,
      rowModelType: "serverSide",
      serverSideStoreType: "partial",
      maxBlocksInCache: 2,
      rowSelection: "multiple",
      pagination: true,
      onGridReady: this.onGridReady,
      onSelectionChanged: this.onSelectionChanged,
    };

    let rowClassRules = {
      rowOdd: function (params) {
        return params.rowIndex % 2 === 0;
      },
      rowEven: function (params) {
        return params.rowIndex % 2 === 1;
      },
    };

    let { open, operationTitle, operationAction, isDelete, reviewOpen, template_uuid } = this.state;
    if (sessionStorage.length === 0) {
      return <Navigate to="/" replace={true} />;
    }
    return (
      <div className="ag-theme-material gridProperties">
        <div>
          <Dialog
            open={open}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            id="messageDialog"
          >
            <DialogTitle id="alert-dialog-title">{operationTitle}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {operationAction}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              {isDelete === true ? (
                <Button
                  onClick={this.handleClose}
                  className="btn-outline-white"
                >
                  No
                </Button>
              ) : (
                ""
              )}
              {isDelete === true ? (
                <Button
                  onClick={this.onRemoveSelectedConfirm}
                  autoFocus
                  className="btn-primary"
                >
                  Yes
                </Button>
              ) : (
                <Button
                  onClick={this.handleClose}
                  autoFocus
                  className="btn-outline-white"
                >
                  No
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </div>
        <box>
          <Container
            maxWidth="xl"
            sx={{ marginTop: 2 }}
            className="breadcrumbs-section"
          >
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Custombreadcrumb hierarchy={this.state.hierarchy} />
              </Grid>
              <Grid item md={6} xs={12} className="btn-section">
                <Typography
                  variant="h8"
                  gutterBottom
                  sx={{
                    color: "#952424",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    gap: "10px",
                    flexWrap: "wrap",
                  }}
                  justify="flex-end"
                >
                  <Button
                    startIcon={<AddIcon />}
                    variant="contained"
                    size="small"
                    sx={{ mb: "5px", mt: "5px" }}
                    onClick={() => this.onOpenAction("crmtemplateadd")}
                  >
                    Add
                  </Button>
                  <Button
                    startIcon={<EditIcon />}
                    variant="contained"
                    size="small"
                    sx={{ mb: "5px", mt: "5px" }}
                    onClick={() => this.reviewClickOpen()}
                  >
                    View
                  </Button>
                  <Button
                    startIcon={<EditIcon />}
                    variant="contained"
                    size="small"
                    sx={{ mb: "5px", mt: "5px" }}
                    onClick={() => this.onEditRow("ringgroupedit")}
                  >
                    Edit
                  </Button>
                  <Button
                    startIcon={<RemoveIcon />}
                    variant="contained"
                    size="small"
                    sx={{ mb: "5px", mt: "5px" }}
                    onClick={() => this.onRemoveSelected()}
                  >
                    Delete
                  </Button>
                  <Button
                    startIcon={<ReplayIcon />}
                    variant="contained"
                    size="small"
                    sx={{ mb: "5px", mt: "5px" }}
                    onClick={() => this.getRecord()}
                  >
                    Reload
                  </Button>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-search"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>{" "}
                    &nbsp;&nbsp;
                    <span>Search</span>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      borderTop: "1px solid #ddd",
                    }}
                  >
                    <Box
                      className="formBox"
                      component="form"
                      sx={{
                        boxShadow: "none",
                        padding: "0 5px",
                        marginTop: "0",
                      }}
                      onSubmit={this.getRecord}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                          <TextField
                            margin="normal"
                            fullWidth
                            id="name"
                            label="Name"
                            name="name"
                            value={this.state.name}
                            onChange={this.onChangeItem}
                            autoComplete="none"
                            type="text"
                            size="small"
                          />
                        </Grid>

                        <Grid
                          xs={12}
                          className="btnSections"
                          justifyContent="center"
                          display="flex"
                          alignItems="center"
                          sx={{
                            gap: "20px",
                            padding: "10px 15px",
                          }}
                        >
                          <Button
                            type="submit"
                            fullWidth
                            className="btn btn-primary"
                            sx={{
                              mt: 1,
                              mb: 1,
                              height: "35px",
                              padding: "5px 20px",
                              width: "100px",
                            }}
                            size="small"
                          >
                            Search
                          </Button>
                          <Button
                            type="reset"
                            fullWidth
                            className="btn btn-outline-white"
                            sx={{
                              mt: 1,
                              mb: 1,
                              height: "35px",
                              width: "100px",
                              padding: "5px 20px",
                            }}
                            size="small"
                            onClick={this.reSet}
                          >
                            Cancel
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </Container>
        </box>
        <AgGridReact
          className="table-structure"
          gridOptions={gridOptions}
          rowModelType="serverSide"
          serverSideStoreType="full"
          rowClassRules={rowClassRules}
          suppressRowClickSelection={true}
          suppressHorizontalScroll={true}
          alwaysShowVerticalScroll={true}
        />
        <div>
          <Dialog
            open={reviewOpen}
            onClose={this.reviewClickClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            id="messageDialog"
            fullWidth={true}
            maxWidth="lg"
          >
            <DialogTitle id="alert-dialog-title">CRM Data Form</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <ReviewsformData template_uuid={template_uuid} reviewClickClose={this.reviewClickClose} />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.reviewClickClose}
                autoFocus
                className="btn-outline-white"
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  }
}

export default withRouter(TemplateData);
