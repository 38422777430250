import React, { Component } from "react";
import Dialer from "./Dialer";
import Reciever from "./Reciever";
import Dtmf from "./Dtmf";
import Phonebutton from "./Phonebutton";
import ring from "./ring.mp3";
import Transfer from "./Transfer";
import Atttransfer from "./Atttransfer";
import Eavesdrop from "./Eavesdrop";
import { withRouter } from "../withRouter";
import { UserAgent, Registerer, Inviter, SessionState, RegistererState, Web } from "sip.js";
import AppConfig from "../../Constants/AppConfig";

let interval = "";
class Phone extends Component {
  constructor(props) {
    super(props);
    this.onCallStarted = this.onCallStarted.bind(this);
    this.enterNumber = this.enterNumber.bind(this);
    this.removeNumber = this.removeNumber.bind(this);
    this.state = {
      currentComponentFlag: 0,
      number: "",
      dtmfNumber: "",
      dtmfLastNumber: "",
      audioRingDom: `<audio autoplay="true" src=${ring} loop="loop"></audio>`,
      opened: false,
      incomingSession: "",
      ua: "",
      isConnected: false,
      dialedOrReceived: false,
      tansferNumber: "",
      attTansferNumber: "",
      attTransferEnabled: false,
      displayName: "",
      call_state: "",
      recordingIconFlag: true,
      minutes: 0,
      second: 0,
    };
  }

  componentDidMount = async () => {
    const uri = UserAgent.makeURI(
      "sip:" +
      sessionStorage.getItem("extension") +
      "@" +
      sessionStorage.getItem("domain_name")
    );

    let config = {
      autoStart: true,
      autoStop: true,
      authorizationPassword: sessionStorage.getItem("extension_password"),
      authorizationUser: sessionStorage.getItem("extension"),
      displayName: sessionStorage.getItem("Username"),
      contactName: sessionStorage.getItem("extension"),
      uri: uri,
      transportOptions: {
        wsServers: [AppConfig.REACT_APP_WSS_URL],
        traceSip: true,
        maxReconnectionAttempts: 100,
        keepAliveInterval: 10,
      },
      hackWssInTransport: true,
      registerExpires: 300,
      hackIpInContact: true,
      logLevel: "debug",
      logBuiltinEnabled: true,
      logConfiguration: true,
      //log: {
      //    level: 2
      //},
      sessionDescriptionHandlerFactoryOptions: {
        constraints: {
          audio: {
            deviceId: "default",
          },
          video: false,
        },
        alwaysAcquireMediaFirst: true,
        iceCheckingTimeout: 500,
      },
    };

    var registererOptions = {
      expires: 300,
    };
    if (AppConfig.REACT_APP_PHONE_ENABLE === '1') {
      const userAgent = new UserAgent(config);

      const registerer = new Registerer(userAgent, registererOptions);

      const obj = this;
      userAgent.delegate = {
        onInvite: function onInvite(invitation) {
          let { incomingSession } = obj.state;
          if (incomingSession && Object.keys(incomingSession).length > 0) {
            console.log('reject', incomingSession.state);
            if (incomingSession.state && !(incomingSession.state === SessionState.Terminated || incomingSession.state === SessionState.Terminating)) {
              invitation.reject();
              return true;
            }
          }
          document.getElementById("audioRing").innerHTML = obj.state.audioRingDom;
          obj.setState({ opened: true, dialedOrReceived: true });
          invitation.stateChange.addListener((newState: SessionState) => {
            switch (newState) {
              case SessionState.Establishing:
                break;
              case SessionState.Established:
                document.getElementById("audioRing").innerHTML = "";
                obj.setState({ opened: false, dialedOrReceived: false });
                obj.attachedMedia(invitation);
                break;
              case SessionState.Terminated:
                document.getElementById("audioRing").innerHTML = "";
                obj.setState({ opened: false, dialedOrReceived: false });
                obj.onCallEnded();
                break;
              default:
                break;
            }
          });

          obj.setState({
            number: invitation.remoteIdentity.uri.normal.user,
            displayName: invitation.remoteIdentity._displayName,
            incomingSession: invitation,
          });
        },
        onDisconnect: function onDisconnect(Error) {
          console.log('call disconnect now..');
          sessionStorage.setItem("isConnected", false);
          obj.setState({ isConnected: false });
          registerer.unregister();

        },
        onConnect: function onConnect(Error) {
          console.log('connection : ', registerer._state);
          if (registerer._state === 'Registered') {
            registerer.unregister();
          }
          registerer.register();
          registerer.stateChange.addListener((newState: RegistererState) => {
            switch (newState) {
              case RegistererState.Initial:
                sessionStorage.setItem("isConnected", false);
                obj.setState({ isConnected: false });
                break
              case RegistererState.Registered:
                sessionStorage.setItem("isConnected", true);
                obj.setState({ isConnected: true });
                break
              case RegistererState.Unregistered:
                sessionStorage.setItem("isConnected", false);
                obj.setState({ isConnected: false });
                break
              case RegistererState.Terminated:
                sessionStorage.setItem("isConnected", false);
                obj.setState({ isConnected: false });
                break
            }
          })
        },
      };

      await userAgent.start().then(() => {
        // registerer.register();
        // registerer.stateChange.addListener((newState: RegistererState) => {
        //   switch (newState) {
        //     case RegistererState.Initial:
        //       sessionStorage.setItem("isConnected", false);
        //       this.setState({ isConnected: false });
        //       break
        //     case RegistererState.Registered:
        //       sessionStorage.setItem("isConnected", true);
        //       this.setState({ isConnected: true });
        //       break
        //     case RegistererState.Unregistered:
        //       sessionStorage.setItem("isConnected", false);
        //       this.setState({ isConnected: false });
        //       break
        //     case RegistererState.Terminated:
        //       sessionStorage.setItem("isConnected", false);
        //       this.setState({ isConnected: false });
        //       break
        //   }
        // })
      }).catch((error) => {
        console.log(error);
      });

      this.setState({ ua: userAgent });


    }

  };

  enterInputNumber = (value) => {
    this.setState({ number: value });
  };

  enterNumber = (value) => {
    let { number } = this.state;
    let newNumber = number + "" + value;
    this.setState({ number: newNumber });
  };

  removeNumber = () => {
    let { number } = this.state;
    let newNumber = number.substring(0, number.length - 1);
    this.setState({ number: newNumber });
  };

  onCallStarted = (value) => {
    if (value !== "") {
      let { ua } = this.state;
      const uri = UserAgent.makeURI(
        "sip:" + value + "@" + sessionStorage.getItem("domain_name")
      );
      var inviter = new Inviter(ua, uri);

      inviter.delegate = {
        onRefer: function onRefer(referral) {
          console.log("Referred: " + referral);
        },
      };
      const obj = this;
      inviter.stateChange.addListener((newState: SessionState) => {
        switch (newState) {
          case SessionState.Establishing:
            break;
          case SessionState.Established:
            obj.setState({
              call_state: '',
              minutes: 0,
              second: 0,
            });
            clearInterval(interval);
            obj.intervalFun();
            obj.attachedMedia(inviter);
            break;
          case SessionState.Terminated:
            obj.onCallEnded();
            break;
          default:
            break;
        }
      });
      obj.setState({
        number: value,
        incomingSession: inviter,
        call_state: 'calling',
      });

      var inviteOptions = {
        requestDelegate: {
          onProgress: (response) => {
            obj.setState({ call_state: 'Ringing' })
          }
        }
      }

      inviter
        .invite(inviteOptions)
        .then(function () {
          obj.setState({ currentComponentFlag: 1 });
        })
      ["catch"](function (error) {
        console.log(error);
      });
    }
  };

  onCallEnded = () => {
    let { incomingSession } = this.state;
    if (incomingSession.state === SessionState.Established) {
      incomingSession.bye();
    } else if (
      incomingSession.state === SessionState.Initial ||
      incomingSession.state === SessionState.Establishing
    ) {
      incomingSession.cancel();
    }

    this.setState({
      currentComponentFlag: 0,
      number: "",
      dialCallButtom: true,
      acceptCallButton: false,
      opened: true,
      dialedOrReceived: false,
      attTransferEnabled: false,
      recordingIconFlag: true,
    });
    clearInterval(interval);
  };

  onCallReject = () => {
    let { incomingSession } = this.state;
    incomingSession.reject();
    this.setState({
      currentComponentFlag: 0,
      number: "",
      dialCallButtom: true,
      acceptCallButton: false,
      dialedOrReceived: false,
    });
  };

  enterDtmfNumber = (value) => {
    let { dtmfNumber, incomingSession } = this.state;
    let newNumber = dtmfNumber + "" + value;
    var options = {
      requestOptions: {
        body: {
          contentDisposition: "render",
          contentType: "application/dtmf-relay",
          content: "Signal=" + value + "\r\nDuration=1000",
        },
      },
    };
    incomingSession.info(options);
    this.setState({ dtmfNumber: newNumber });
    this.setState({ dtmfLastNumber: value });
  };

  removeDtmfNumber = () => {
    this.setState({ currentComponentFlag: 1, dtmfNumber: "" });
  };

  callComponent = (value) => {
    this.setState({ currentComponentFlag: value });
  };

  openedClosed = () => {
    let flag = !this.state.opened ? true : false;
    this.setState({ opened: flag });
  };

  onAnswerCall = async (value) => {
    let { incomingSession, displayName } = this.state;
    if (value !== "") {
      await incomingSession.accept({
        sessionDescriptionHandlerOptions: {
          constraints: {
            audio: true,
            video: false,
          },
        },
      });
      if (displayName === "eavesdrop") {
        this.setState({ currentComponentFlag: 5, opened: true });
      } else {
        this.setState({ currentComponentFlag: 1, opened: true });
      }
    } else {
      this.setState({ currentComponentFlag: 0 });
    }
    this.setState({
      call_state: '',
      minutes: 0,
      second: 0,
    });
    clearInterval(interval);
    this.intervalFun();
  };

  muteMethod = () => {
    let { isMute, incomingSession } = this.state;
    let pc = "";
    if (isMute) {
      pc = incomingSession.sessionDescriptionHandler.peerConnection;
      pc.getSenders().forEach(function (stream) {
        if (stream.track && stream.track.kind === "audio") {
          stream.track.enabled = true;
        }
      });
    } else {
      pc = incomingSession.sessionDescriptionHandler.peerConnection;
      pc.getSenders().forEach(function (stream) {
        if (stream.track && stream.track.kind === "audio") {
          stream.track.enabled = false;
        }
      });
    }

    this.setState({ isMute: !isMute });
  };

  holdMethod = async () => {
    let { isHold, incomingSession } = this.state;
    let options = {};
    if (isHold) {
      options = {
        sessionDescriptionHandlerModifiers: []
      }
    } else {
      options = {
        sessionDescriptionHandlerModifiers: [Web.holdModifier]
      }
    }

    incomingSession.invite(options);
    this.setState({ isHold: !isHold });
  };

  attachedMedia = (session) => {
    let pc = session.sessionDescriptionHandler.peerConnection;
    let Stream = "";
    if (pc.getReceivers) {
      Stream = new window.MediaStream();
      pc.getReceivers().forEach(function (receiver) {
        var track = receiver.track;
        console.log(track);
        if (track) {
          Stream.addTrack(track);
        }
      });
    } else {
      Stream = pc.getRemoteStreams()[0];
    }

    var domElement = document.getElementById("remoteVoice");
    domElement.srcObject = Stream;
    domElement.play();

    let pc2 = session.sessionDescriptionHandler.peerConnection;
    pc2.getSenders().forEach(function (sender) {
      if (sender.track && sender.track.kind === "audio") {
        var audioDeviceId = "default";
        navigator.mediaDevices
          .getUserMedia({
            audio: {
              deviceId: audioDeviceId,
            },
          })
          .then(function (stream) {
            var audioTrack = stream.getAudioTracks();
            if (audioTrack) {
              sender.replaceTrack(audioTrack[0]);
            }
          });
      }
    });
  };

  sendDtmf = (session, value) => {
    var options = {
      requestOptions: {
        body: {
          contentDisposition: "render",
          contentType: "application/dtmf-relay",
          content: "Signal=" + value + "\r\nDuration=1000",
        },
      },
    };
    session.info(options);
    return true;
  };

  onTransferCallStarted = async (value) => {
    let { incomingSession } = this.state;
    await this.sendDtmf(incomingSession, "*");
    await this.sendDtmf(incomingSession, "1");
    await new Promise((resolve) => setTimeout(resolve, 1000));
    let singleDigit = "";
    for (let index = 0; index < value.length; index++) {
      singleDigit = value.substr(index, 1);
      await this.sendDtmf(incomingSession, singleDigit);
    }
    await this.sendDtmf(incomingSession, "#");
  };

  enterTransferNumber = (value) => {
    let { tansferNumber } = this.state;
    let newNumber = tansferNumber + "" + value;
    this.setState({ tansferNumber: newNumber });
  };

  removeTransferNumber = () => {
    let { tansferNumber } = this.state;
    let newNumber = tansferNumber.substring(0, tansferNumber.length - 1);
    this.setState({ tansferNumber: newNumber });
  };

  onAttTransferCallStarted = async (value) => {
    let { incomingSession } = this.state;
    await this.sendDtmf(incomingSession, "*");
    await this.sendDtmf(incomingSession, "4");
    await new Promise((resolve) => setTimeout(resolve, 1000));
    let singleDigit = "";
    for (let index = 0; index < value.length; index++) {
      singleDigit = value.substr(index, 1);
      await this.sendDtmf(incomingSession, singleDigit);
    }
    await this.sendDtmf(incomingSession, "#");
    this.setState({ attTransferEnabled: true });
  };

  enterAttTransferNumber = (value) => {
    let { attTansferNumber } = this.state;
    let newNumber = attTansferNumber + "" + value;
    this.setState({ attTansferNumber: newNumber });
  };

  removeAttTransferNumber = () => {
    let { attTansferNumber } = this.state;
    let newNumber = attTansferNumber.substring(0, attTansferNumber.length - 1);
    this.setState({ attTansferNumber: newNumber });
  };

  onConferenceCall = async () => {
    let { incomingSession } = this.state;
    await this.sendDtmf(incomingSession, "0");
    this.setState({ attTransferEnabled: false });
  };

  eavesdropAction = async (key) => {
    let { incomingSession } = this.state;
    switch (key) {
      case "restore":
        await this.sendDtmf(incomingSession, "0");
        break;
      case "local":
        await this.sendDtmf(incomingSession, "1");
        break;
      case "remote":
        await this.sendDtmf(incomingSession, "2");
        break;
      case "conference":
        await this.sendDtmf(incomingSession, "3");
        break;
      default:
        break;
    }
  };

  onRecordingCall = async () => {
    let { incomingSession } = this.state;
    await this.sendDtmf(incomingSession, "*");
    await this.sendDtmf(incomingSession, "2");
    this.setState({ recordingIconFlag: false });
  };

  intervalFun = () => {
    interval = setInterval(() => {
      let { minutes, second } = this.state;
      let newSecond = second === 59 ? 0 : second + 1;
      let newMinutes = newSecond === 59 ? minutes + 1 : minutes;
      this.setState({ second: newSecond, minutes: newMinutes });
    }, 1000);
  };

  render() {
    let {
      currentComponentFlag,
      number,
      dtmfNumber,
      opened,
      incomingSession,
      isConnected,
      dialedOrReceived,
      isMute,
      isHold,
      tansferNumber,
      attTansferNumber,
      attTransferEnabled,
      displayName,
      call_state,
      recordingIconFlag,
      minutes,
      second,
    } = this.state;
    let currentComponent = "";
    if (currentComponentFlag === 0) {
      currentComponent = (
        <Dialer
          onCallStarted={
            dialedOrReceived === false ? this.onCallStarted : this.onAnswerCall
          }
          enterNumber={this.enterNumber}
          enterInputNumber={this.enterInputNumber}
          removeNumber={this.removeNumber}
          number={number}
          opened={opened}
          dialedOrReceived={dialedOrReceived}
          onCallReject={this.onCallReject}
        />
      );
    } else if (currentComponentFlag === 1) {
      currentComponent = (
        <Reciever
          onCallEnded={this.onCallEnded}
          callComponent={this.callComponent}
          number={number}
          opened={opened}
          incomingSession={incomingSession}
          muteMethod={this.muteMethod}
          isMute={isMute}
          holdMethod={this.holdMethod}
          isHold={isHold}
          attTransferEnabled={attTransferEnabled}
          onConferenceCall={this.onConferenceCall}
          call_state={call_state}
          onRecordingCall={this.onRecordingCall}
          recordingIconFlag={recordingIconFlag}
          minutes={minutes}
          second={second}
        />
      );
    } else if (currentComponentFlag === 2) {
      currentComponent = (
        <Dtmf
          enterDtmfNumber={this.enterDtmfNumber}
          removeDtmfNumber={this.removeDtmfNumber}
          onCallEnded={this.onCallEnded}
          dtmfNumber={dtmfNumber}
          number={number}
          opened={opened}
        />
      );
    } else if (currentComponentFlag === 3) {
      currentComponent = (
        <Transfer
          enterTransferNumber={this.enterTransferNumber}
          removeTransferNumber={this.removeTransferNumber}
          callComponent={this.callComponent}
          onTransferCallStarted={this.onTransferCallStarted}
          tansferNumber={tansferNumber}
          number={number}
          opened={opened}
        />
      );
    } else if (currentComponentFlag === 4) {
      currentComponent = (
        <Atttransfer
          enterAttTransferNumber={this.enterAttTransferNumber}
          removeAttTransferNumber={this.removeAttTransferNumber}
          onAttTransferCallStarted={this.onAttTransferCallStarted}
          callComponent={this.callComponent}
          attTansferNumber={attTansferNumber}
          number={number}
          opened={opened}
        />
      );
    } else if (currentComponentFlag === 5) {
      currentComponent = (
        <Eavesdrop
          onCallEnded={this.onCallEnded}
          callComponent={this.callComponent}
          number={number}
          opened={opened}
          incomingSession={incomingSession}
          displayName={displayName}
          eavesdropAction={this.eavesdropAction}
        />
      );
    }


    return (
      <div>
        {currentComponent}
        <Phonebutton
          isConnected={isConnected}
          opened={opened}
          openedClosed={this.openedClosed}
        />
        <audio id="remoteVoice"></audio>
        <div id="audioRing" name="audioRing"></div>
      </div>
    );
  }
}

export default withRouter(Phone);
