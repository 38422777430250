import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import { commonPostAPI } from "../../commoncall/commoncall.js";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "../../Assets/index.css";
import AG_GRID_LOCALE_EN from "../../language/locale.en.js";
import { Box, Grid, Button, Typography, TextField, Select, MenuItem, InputLabel } from "@mui/material";
import Container from "@mui/material/Container";
import Custombreadcrumb from "../Custombreadcrumb.js";
import { Navigate } from "react-router-dom";
import ReplayIcon from "@mui/icons-material/Replay";
import { withRouter } from "../withRouter.js";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import AppConfig from "../../Constants/AppConfig";

var partialState = {};
var gridApiObj;
class TransactionData extends Component {

  constructor(props) {
    super(props);

    this.state = {
      properties: {
        open: false,
        columnDefs: [
          {
            headerName: "Sr.No",
            valueGetter: "node.rowIndex + 1",
            filter: false,
            editable: false,
            headerCheckboxSelection: true,
            checkboxSelection: false,
            showDisabledCheckboxes: false,
            maxWidth: 150,
          },
          {
            field: "domain_name",
            headerName: "Domain",
            filter: "agTextColumnFilter",
            tooltipField: "Domain",
            cellStyle: { color: "blue" },
            editable: false,
            maxWidth: 200,
          },
          {
            field: "transaction_type",
            headerName: "Transaction Type",
            filter: "agTextColumnFilter",
            tooltipField: "Transaction Type",
            editable: false,
            maxWidth: 200,
          },
          {
            field: "amount",
            headerName: "Amount",
            filter: "agTextColumnFilter",
            tooltipField: "Amount",
            editable: false,
            minWidth: 200,
          },
          {
            field: "username",
            headerName: "Update By",
            filter: "agTextColumnFilter",
            tooltipField: "Update By",
            editable: false,
            maxWidth: 200,
          },
          {
            field: "update_time",
            headerName: "Update Time",
            filter: "agTextColumnFilter",
            tooltipField: "Update Time",
            editable: false,
            minWidth: 200,
          },
        ],
        rowModelType: "serverSide",
        serverSideStoreType: "full",
        enableBrowserTooltips: true,
      },
      paginationPageSize: 50,
      currentPage: 1,
      operationActionText: "",
      open: false,
      operationTitle: "",
      operationAction: "",
      isDelete: false,
      hierarchy: [
        {
          label: "Home",
          action: "",
        },
        {
          label: "Transactions",
          action: "",
        },
      ],
      domain_uuid: "0",
      transaction_type: "0",
      domain_list: [],
    };

    this.rowSelectionObj = [];
  }

  async componentDidMount() {
    let responseList = await commonPostAPI("domainlist", {});
    this.setState({ domain_list: responseList });
  }

  createServerSideDatasource = (server) => {
    return {
      getRows: (params) => {
        var response = server.getData(params.request);
        setTimeout(function () {
          if (response.success) {
            params.success({
              rowData: response.rows,
              rowCount: response.lastRow,
            });
          } else {
            params.fail();
          }
        }, 1000);
      },
    };
  };

  createFakeServer = (allData) => {
    return {
      getData: (request) => {
        var rowsForBlock = allData.slice(request.startRow, request.endRow);
        var lastRow = this.getLastRowIndex(request, rowsForBlock);
        return {
          success: true,
          rows: rowsForBlock,
          lastRow: lastRow,
        };
      },
    };
  };

  getLastRowIndex = (request, results) => {
    if (!results) return undefined;
    var currentLastRow = (request.startRow || 0) + results.length;
    return currentLastRow < (request.endRow || 0) ? currentLastRow : undefined;
  };

  onGridReady = async (params) => {
    gridApiObj = params.api;
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    const updateData = (data) => {
      var fakeServer = this.createFakeServer(data);
      var datasource = this.createServerSideDatasource(fakeServer);
      params.api.setServerSideDatasource(datasource);
    };

    let pageObj = {
      currentPage: 1,
      paginationPageSize: this.state.paginationPageSize,
      transaction_type: (this.state.transaction_type !== "0") ? this.state.transaction_type : "",
      domain_uuid: (this.state.domain_uuid !== "0") ? this.state.domain_uuid : "",
    };

    let response = await commonPostAPI("transactions", pageObj);
    updateData(response);
  };

  getRecord = async (e) => {
    e.preventDefault();
    let pageObj = {
      currentPage: 1,
      paginationPageSize: this.state.paginationPageSize,
      transaction_type: (this.state.transaction_type !== "0") ? this.state.transaction_type : "",
      domain_uuid: (this.state.domain_uuid !== "0") ? this.state.domain_uuid : "",
    };
    this.rowSelectionObj = {};
    let response = await commonPostAPI("transactions", pageObj);
    const updateData = (data) => {
      var fakeServer = this.createFakeServer(data);
      var datasource = this.createServerSideDatasource(fakeServer);
      gridApiObj.setServerSideDatasource(datasource);
    };
    updateData(response);
  };

  getRefresh = async () => {
    let pageObj = {
      currentPage: 1,
      paginationPageSize: this.state.paginationPageSize,
      transaction_type: (this.state.transaction_type !== "0") ? this.state.transaction_type : "",
      domain_uuid: (this.state.domain_uuid !== "0") ? this.state.domain_uuid : "",
    };
    this.rowSelectionObj = {};
    let response = await commonPostAPI("transactions", pageObj);
    const updateData = (data) => {
      var fakeServer = this.createFakeServer(data);
      var datasource = this.createServerSideDatasource(fakeServer);
      gridApiObj.setServerSideDatasource(datasource);
    };
    updateData(response);
  };

  reSet = () => {
    var partialState = {
      transaction_type: '',
      domain_name: "",
    };
    this.setState(partialState);
  }

  onOpenAction = (action) => {
    this.props.navigate(`/${action}`);
  };

  onChangeItem = (e) => {
    e.preventDefault();
    partialState[e.target.name] = e.target.value;
    this.setState(partialState);
  }

  render() {

    let gridOptions = {
      defaultColDef: {
        flex: 1,
        sortable: true,
        filter: true,
        resizable: true,
        autoHeight: false,
        editable: true,
        floatingFilter: false,
      },
      localeText: AG_GRID_LOCALE_EN,
      columnDefs: this.state.properties.columnDefs,
      cacheBlockSize: this.state.paginationPageSize,
      paginationPageSize: this.state.paginationPageSize,
      rowBuffer: 0,
      rowModelType: "serverSide",
      serverSideStoreType: "partial",
      maxBlocksInCache: 2,
      rowSelection: false,
      pagination: true,
      onGridReady: this.onGridReady,
    };

    let {
      domain_list
    } = this.state;

    let rowClassRules = {
      rowOdd: function (params) {
        return params.rowIndex % 2 === 0;
      },
      rowEven: function (params) {
        return params.rowIndex % 2 === 1;
      },
    };

    if (sessionStorage.length === 0) {
      return <Navigate to="/" replace={true} />;
    }

    return (
      <div className="ag-theme-material gridProperties">
        <box>
          <Container
            maxWidth="xl"
            sx={{ marginTop: 2 }}
            className="breadcrumbs-section"
          >
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Custombreadcrumb hierarchy={this.state.hierarchy} />
              </Grid>
              <Grid item md={6} xs={12} className="btn-section">
                <Typography
                  variant="h8"
                  gutterBottom
                  sx={{
                    color: "#952424",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    gap: "10px",
                    flexWrap: "wrap",
                  }}
                  justify="flex-end"
                >
                  {((sessionStorage.getItem('TRANSACTIONS') & AppConfig.ADD_ACTION) === AppConfig.ADD_ACTION) ?
                    <Button
                      startIcon={<AddIcon />}
                      variant="contained"
                      size="small"
                      sx={{ mb: "5px", mt: "5px" }}
                      onClick={() => this.onOpenAction("transactionadd")}
                    >
                      Add
                    </Button> : ""}
                  {((sessionStorage.getItem('TRANSACTIONS') & AppConfig.VIEW_ACTION) === AppConfig.VIEW_ACTION) ?
                    <Button
                      startIcon={<ReplayIcon />}
                      variant="contained"
                      size="small"
                      onClick={() => this.getRefresh()}
                    >
                      Reload
                    </Button> : ""}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-search"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>{" "}
                    &nbsp;&nbsp;
                    <span>Search</span>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      borderTop: "1px solid #ddd",
                    }}
                  >
                    <Box
                      className="formBox"
                      component="form"
                      sx={{
                        boxShadow: "none",
                        padding: "0 5px",
                        marginTop: "0",
                      }}
                      onSubmit={this.getRecord}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                          <InputLabel id="demo-simple-select-label">
                            Domain Name
                          </InputLabel>
                          <Select
                            fullWidth
                            labelId="domain_uuid"
                            id="domain_uuid"
                            value={this.state.domain_uuid}
                            label="Domain"
                            name="domain_uuid"
                            onChange={this.onChangeItem}
                            size="small"
                            sx={{ mt: 2 }}
                            autoComplete="none"
                          >
                            <MenuItem value="0">Select</MenuItem>
                            {domain_list.map((item) => (
                              <MenuItem value={item.domain_uuid}>{item.domain_name}</MenuItem>
                            ))}
                          </Select>

                        </Grid>
                        <Grid item xs={12} md={4}>
                          <InputLabel id="demo-simple-select-label">
                            Transaction Type
                          </InputLabel>
                          <Select
                            fullWidth
                            labelId="transaction_type"
                            id="transaction_type"
                            value={
                              this.state.transaction_type
                            }
                            label="transaction_type"
                            name="transaction_type"
                            onChange={this.onChangeItem}
                            size="small"
                            autoComplete="none"
                          >
                            <MenuItem value="0">Select</MenuItem>
                            <MenuItem value="credit">Credit</MenuItem>
                            <MenuItem value="debit">Debit</MenuItem>
                          </Select>
                        </Grid>
                        <Grid
                          xs={12}
                          className="btnSections"
                          justifyContent="center"
                          display="flex"
                          alignItems="center"
                          autoComplete="none"
                          sx={{
                            gap: "20px",
                            padding: "10px 15px",
                          }}
                        >
                          <Button
                            type="submit"
                            fullWidth
                            className="btn btn-primary"
                            sx={{
                              mt: 1,
                              mb: 1,
                              height: "35px",
                              padding: "5px 20px",
                              width: "100px",
                            }}
                            size="small"
                          >
                            Search
                          </Button>
                          <Button
                            type="reset"
                            fullWidth
                            className="btn btn-outline-white"
                            sx={{
                              mt: 1,
                              mb: 1,
                              height: "35px",
                              width: "100px",
                              padding: "5px 20px",
                            }}
                            size="small"
                            onClick={this.reSet}
                          >
                            Cancel
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </Container>
        </box>
        <div style={{ width: "100%", height: '100%' }}>
          <AgGridReact
            id="transactionGrid"
            className="table-structure"
            gridOptions={gridOptions}
            rowModelType="serverSide"
            serverSideStoreType="full"
            rowClassRules={rowClassRules}
            suppressRowClickSelection={false}
            suppressHorizontalScroll={true}
            alwaysShowVerticalScroll={true}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(TransactionData);

