import React, { Component } from "react";
import { commonPostAPI } from "../../commoncall/commoncall";
import { Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { withRouter } from "../withRouter";
import Custombreadcrumb from "../Custombreadcrumb";
import { Button, Grid } from "@mui/material";

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};

var partialState = {};
class RecordingaddData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {
        recording_filename: "Please choose greeting file.",
      },
      alertMessage: "",
      recording_filename: "",
      selectedFile: "",
      hierarchy: [
        {
          label: "Home",
          action: "",
        },
        {
          label: "Greeting",
          action: "recording",
        },
        {
          label: "Add Greeting",
          action: "recordingadd",
        },
      ],
    };
  }

  saveData = async (event) => {
    event.preventDefault();
    if (validateForm(this.state.errors)) {
      const fileFileType = ["audio/wav", "audio/mpeg"];
      var data = fileFileType.filter((val) =>
        val.includes(this.state.selectedFile.type)
      );
      if (data === "") {
        this.setState({ alertMessage: "Allow upload audio file only" });
      } else {
        this.setState({ alertMessage: "" });
        var formData = new FormData();
        formData.append("file", this.state.selectedFile);
        formData.append("filename", this.state.selectedFile.name);
        formData.append("domain_uuid", sessionStorage.getItem("domain_uuid"));
        formData.append("domain_name", sessionStorage.getItem("domain_name"));

        await commonPostAPI("addrecordings", formData);
        this.onOpenAction("recording");
      }
    } else {
      this.setState({
        alertMessage: <div>Please enter required parameters.</div>,
      });
    }
  };

  onOpenAction = (action) => {
    this.props.navigate(`/${action}`);
  };

  handleChangeMultiple = (e) => {
    const selected = e.target.value;
    this.setState({ users: selected });
  };

  onChangeItem = (e) => {
    e.preventDefault();
    partialState[e.target.name] = e.target.value;
    this.setState({ selectedFile: e.target.files[0] });
    let errors = this.state.errors;
    switch (e.target.name) {
      case "recording_filename":
        errors.recording_filename =
          e.target.value.length < 1 ? "Please choose greeting file." : "";
        break;
      default:
        break;
    }

    this.setState({ errors, [e.target.name]: e.target.value });
    this.setState(partialState);
  };

  render() {
    if (sessionStorage.length === 0) {
      return <Navigate to="/" replace={true} />;
    }

    const theme = createTheme();
    let { errors, recording_filename, alertMessage } = this.state;
    return (
      <div className="submit-form formElements">
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xl">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "15px 0",
              }}
            >
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Custombreadcrumb hierarchy={this.state.hierarchy} />
                </Grid>
              </Grid>
              <Box className="formBox">
                <Box
                  component="form"
                  onSubmit={this.saveData}
                  noValidate
                  sx={{ mt: 1, width: "100%", padding: "0 15px" }}
                >
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={6}>
                      <label>Greeting</label>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="recording_filename"
                        name="recording_filename"
                        value={recording_filename ?? ""}
                        onChange={this.onChangeItem}
                        autoComplete="none"
                        type="file"
                        size="small"
                      />
                      {errors.recording_filename.length > 0 ? (
                        <Stack className="error-show" spacing={2}>
                          {errors.recording_filename}
                        </Stack>
                      ) : (
                        ""
                      )}
                      {alertMessage ? (
                        <Stack className="error-show" spacing={2}>
                          {alertMessage}
                        </Stack>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid container justifyContent="flex-end" xs={12}>
                      <table className="form-btns">
                        <tr>
                          <td>
                            <Button
                              type="submit"
                              fullWidth
                              className="btn btn-primary"
                              sx={{
                                mt: 1,
                                mb: 1,
                                height: "35px",
                                padding: "5px 20px",
                              }}
                              size="small"
                            >
                              Submit
                            </Button>
                          </td>
                          <td>
                            <Button
                              type="button"
                              fullWidth
                              className="btn btn-outline-white"
                              sx={{
                                mt: 1,
                                mb: 1,
                                height: "35px",
                                padding: "5px 20px",
                                marginLeft: "10px",
                              }}
                              size="small"
                              onClick={() => this.onOpenAction("recording")}
                            >
                              Back
                            </Button>
                          </td>
                        </tr>
                      </table>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      </div>
    );
  }
}

export default withRouter(RecordingaddData);
