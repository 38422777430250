import React, { Component } from "react";
import { commonPostAPI } from "../../commoncall/commoncall";
import { Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { withRouter } from "../withRouter";
import { Button, Grid } from "@mui/material";
import Custombreadcrumb from "../Custombreadcrumb";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};

var partialState = {
  domain_uuid: "",
  domain_name: "",
  balance: "",
  rateplanname: "",
  credit_limit: "",
  rateplan_uuid: "0",
};

class BillingeditData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      alertMessage: "",
      domain_uuid: "",
      balance: "0.000000",
      credit_limit: "0.000000",
      rateplan_uuid: "0",
      rateplanlist: [],
      hierarchy: [
        {
          label: "Home",
          action: "",
        },
        {
          label: "Billing Info",
          action: "billings",
        },
        {
          label: "Edit Billing Info",
          action: "",
        },
      ],
    };
  }

  componentDidMount = async () => {
    var url = window.location.pathname;
    var domain_uuid = url.substring(url.lastIndexOf("/") + 1);
    await this.getRecordByID(domain_uuid);
    let responseData1 = await commonPostAPI("rateplanlist", { domain_uuid: domain_uuid });
    this.setState({ rateplanlist: responseData1 });
  }



  getRecordByID = async (id) => {
    let responseData = await commonPostAPI("getBillinginfoDataByID", { domain_uuid: id });
    var dbData = {
      domain_uuid: id,
      domain_name: responseData.domain_name,
      rateplan_uuid: (responseData.rateplan_uuid !== null) ? responseData.rateplan_uuid : "0",
      balance: responseData.balance,
      credit_limit: responseData.credit_limit,
    };
    console.log(dbData);
    partialState = dbData;
    this.setState(partialState);
  };

  saveData = async (event) => {
    event.preventDefault();
    if (validateForm(this.state.errors)) {
      this.setState({ alertMessage: "" });
      let requestparams = {
        domain_uuid: this.state.domain_uuid,
        rateplan_uuid: this.state.rateplan_uuid,
        credit_limit: this.state.credit_limit,
      };
      await commonPostAPI("balanceinfoupdate", requestparams);
      this.onOpenAction("billings");
    } else {
      this.setState({
        alertMessage: <div>Please enter required parameters.</div>,
      });
    }
  };

  onOpenAction = (action) => {
    this.props.navigate(`/${action}`);
  };

  onChangeItem = (e) => {
    e.preventDefault();
    partialState[e.target.name] = e.target.value;
    this.setState(partialState);
  };

  render() {
    if (sessionStorage.length === 0) {
      return <Navigate to="/" replace={true} />;
    }

    const theme = createTheme();
    let {
      errors,
      alertMessage,
      domain_name,
      balance,
      credit_limit,
      rateplan_uuid,
      rateplanlist
    } = this.state;
    console.log(rateplan_uuid);
    return (
      <div>
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xl">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "0 0",
              }}
            >
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Custombreadcrumb hierarchy={this.state.hierarchy} />
                </Grid>
              </Grid>
              <Box className="formBox">
                <Box
                  component="form"
                  onSubmit={this.saveData}
                  noValidate
                  sx={{ mt: 0, width: "100%" }}
                >
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={3}>
                      <label>Domain</label>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="domain_name"
                        name="domain_name"
                        value={domain_name ?? ""}
                        onChange={this.onChangeItem}
                        autoComplete="none"
                        autoFocus
                        type="text"
                        size="small"
                        disabled
                      />
                    </Grid>
                    <Grid item md={3}>
                      <label>Balance</label>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="balance"
                        name="balance"
                        value={balance ?? ""}
                        onChange={this.onChangeItem}
                        autoComplete="none"
                        autoFocus
                        type="text"
                        size="small"
                        disabled
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={3}>
                      <label>Credit Limit</label>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="credit_limit"
                        name="credit_limit"
                        value={credit_limit ?? ""}
                        onChange={this.onChangeItem}
                        autoComplete="none"
                        autoFocus
                        type="number"
                        size="small"
                      />
                    </Grid>

                    <Grid item md={3}>
                      <label>Rate Plan</label>
                      <Select
                        fullWidth
                        labelId="rateplan_uuid"
                        id="rateplan_uuid"
                        value={rateplan_uuid}
                        label="Rate Plan"
                        name="rateplan_uuid"
                        onChange={this.onChangeItem}
                        size="small"
                        sx={{ mt: 2 }}
                        autoComplete="none"
                      >
                        <MenuItem value="0">Select</MenuItem>
                        {rateplanlist.map((item) => (
                          <MenuItem value={item.rateplan_uuid}>{item.name}</MenuItem>
                        ))}
                      </Select>
                    </Grid>

                  </Grid>

                  <Grid container xs={12}>&nbsp;</Grid>

                  {alertMessage ? (
                    <Stack className="error-show" spacing={2}>
                      {alertMessage}
                    </Stack>
                  ) : (
                    ""
                  )}
                  <Grid container xs={12}>&nbsp;</Grid>
                  <Grid container justifyContent="flex-end" xs={12}>
                    <table className="form-btns">
                      <tr>
                        <td>
                          <Button
                            type="submit"
                            fullWidth
                            className="btn btn-primary"
                            sx={{
                              mt: 1,
                              mb: 1,
                              height: "35px",
                              padding: "5px 20px",
                            }}
                            size="small"
                          >
                            Submit
                          </Button>
                        </td>
                        <td>
                          <Button
                            type="button"
                            fullWidth
                            className="btn btn-outline-white"
                            sx={{
                              mt: 1,
                              mb: 1,
                              height: "35px",
                              padding: "5px 20px",
                              marginLeft: "10px",
                            }}
                            size="small"
                            onClick={() => this.onOpenAction("billings")}
                          >
                            Back
                          </Button>
                        </td>
                      </tr>
                    </table>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      </div >
    );
  }
}

export default withRouter(BillingeditData);
