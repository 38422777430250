import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import { commonPostAPI, commonDelAPI } from "../../commoncall/commoncall";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import "../../Assets/index.css";
import AG_GRID_LOCALE_EN from "../../language/locale.en.js";
import { Button, Typography, Box, TextField } from "@mui/material";
import { Navigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import RemoveIcon from "@mui/icons-material/Remove";
import ReplayIcon from "@mui/icons-material/Replay";
import { withRouter } from "../withRouter";
import Container from "@mui/material/Container";
import Custombreadcrumb from "../Custombreadcrumb";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import StopCircleIcon from '@mui/icons-material/StopCircle';

var partialState = {};
var gridApiObj;

class CampaignData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      properties: {
        open: false,
        columnDefs: [
          {
            headerName: "Sr.No",
            valueGetter: "node.rowIndex + 1",
            filter: false,
            editable: false,
            headerCheckboxSelection: true,
            checkboxSelection: true,
            showDisabledCheckboxes: true,
            maxWidth: 100,
          },
          {
            field: "campaign_name",
            headerName: "Campaign",
            filter: "agTextColumnFilter",
            tooltipField: "Campaign Name",
            cellStyle: { color: "blue" },
            editable: false,
            minWidth: 200,
          },
          {
            field: "campaign_type",
            headerName: "Type",
            filter: "agTextColumnFilter",
            tooltipField: "Type",
            minWidth: 150,
          },
          {
            field: "gateway",
            headerName: "Gateway",
            filter: "agTextColumnFilter",
            tooltipField: "Gateway",
            minWidth: 200,
          },
          {
            field: "dial_speed",
            headerName: "Speed",
            filter: "agTextColumnFilter",
            tooltipField: "Dial Speed",
            maxWidth: 120,
          },
          {
            field: "list_name",
            headerName: "Lead List",
            filter: "agTextColumnFilter",
            tooltipField: "Dial Speed",
            maxWidth: 200,
          },
          {
            field: "active",
            headerName: "Status",
            filter: "agTextColumnFilter",
            tooltipField: "Status",
            editable: false,
            cellRenderer: this.CustomButtonComponent,
            minWidth: 200,
          },
        ],
        rowModelType: "serverSide",
        serverSideStoreType: "full",
        enableBrowserTooltips: true,
      },
      paginationPageSize: 50,
      currentPage: 1,
      operationActionText: "",
      open: false,
      operationTitle: "",
      operationAction: "",
      isDelete: false,
      hierarchy: [
        {
          label: "Home",
          action: "",
        },
        {
          label: "Campaign",
          action: "Campaign",
        },
      ],
      name: '',
      rule: '0',
    };

    this.rowSelectionObj = [];
  }

  CustomButtonComponent = (props) => {
    const row = props.data;
    let label = (row.active && row.active === 'Active') ? 'Stop' : 'Start';
    const myIcon = (row.active && row.active === 'Active') ? <StopCircleIcon /> : <PlayCircleFilledWhiteIcon />;
    const status = (row.active && row.active === 'Active') ? 'false' : 'true';
    const color = (row.active && row.active === 'Active') ? 'error' : 'success';

    return <Button
      startIcon={myIcon}
      variant="contained"
      color={color}
      size="small"
      sx={{ mb: "5px", mt: "5px" }}
      onClick={() => this.actionChange(row, status)}
    >
      {label}
    </Button>;
  };

  actionChange = async (rowData, status) => {
    const requestparams = {
      campaign_uuid: rowData.campaign_uuid,
      dialplan_uuid: rowData.dialplan_uuid,
      domain_name: rowData.domain_name,
      status: status
    };
    let response = await commonPostAPI("campaignstatuschange", requestparams);
    this.getRefresh();
  }


  createServerSideDatasource = (server) => {
    return {
      getRows: (params) => {
        var response = server.getData(params.request);
        setTimeout(function () {
          if (response.success) {
            params.success({
              rowData: response.rows,
              rowCount: response.lastRow,
            });
          } else {
            params.fail();
          }
        }, 1000);
      },
    };
  };

  createFakeServer = (allData) => {
    return {
      getData: (request) => {
        var rowsForBlock = allData.slice(request.startRow, request.endRow);
        var lastRow = this.getLastRowIndex(request, rowsForBlock);
        return {
          success: true,
          rows: rowsForBlock,
          lastRow: lastRow,
        };
      },
    };
  };

  getLastRowIndex = (request, results) => {
    if (!results) return undefined;
    var currentLastRow = (request.startRow || 0) + results.length;
    return currentLastRow < (request.endRow || 0) ? currentLastRow : undefined;
  };

  onGridReady = async (params) => {
    gridApiObj = params.api;
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    const updateData = (data) => {
      var fakeServer = this.createFakeServer(data);
      var datasource = this.createServerSideDatasource(fakeServer);
      params.api.setServerSideDatasource(datasource);
    };

    let response = await commonPostAPI("campaigndata", {});
    updateData(response);
  };

  getRecord = async (e) => {
    e.preventDefault();
    let pageObj = {
      currentPage: 1,
      paginationPageSize: this.state.paginationPageSize,
      name: this.state.name,
    };
    this.rowSelectionObj = {};
    let response = await commonPostAPI("campaigndata", pageObj);
    const updateData = (data) => {
      var fakeServer = this.createFakeServer(data);
      var datasource = this.createServerSideDatasource(fakeServer);
      gridApiObj.setServerSideDatasource(datasource);
    };
    updateData(response);
  };

  getRefresh = async () => {
    let pageObj = {
      currentPage: 1,
      paginationPageSize: this.state.paginationPageSize,
      name: this.state.name,
    };
    this.rowSelectionObj = {};
    let response = await commonPostAPI("campaigndata", pageObj);
    const updateData = (data) => {
      var fakeServer = this.createFakeServer(data);
      var datasource = this.createServerSideDatasource(fakeServer);
      gridApiObj.setServerSideDatasource(datasource);
    };
    updateData(response);
  };

  onOpenAction = (action) => {
    this.props.navigate(`/${action}`);
  };

  onSelectionChanged = (params) => {
    const selectedRows = params.api.getSelectedRows();
    let row = {};
    let cloneSelectedRows = selectedRows.map((item) => {
      row = { ...item };
      row["domain_name"] = sessionStorage.getItem("domain_name");
      return row;
    });
    this.rowSelectionObj = cloneSelectedRows;
  };

  onEditRow = async () => {
    if (typeof this.rowSelectionObj === "undefined") {
      this.setState({
        operationTitle: "Row Selection!",
        operationAction: "Please select record",
      });
      this.handleClickOpen();
      return true;
    } else {
      var count = Object.keys(this.rowSelectionObj).length;
      if (count === 0) {
        this.setState({
          operationTitle: "Row Selection!",
          operationAction: "Please select record!",
        });
        this.handleClickOpen();
        return true;
      } else if (count > 1) {
        this.setState({
          operationTitle: "Row Selection!",
          operationAction: "Please select single record only",
        });
        this.handleClickOpen();
        return true;
      }
    }
    var getID = this.rowSelectionObj.map((row) => {
      return row.campaign_uuid;
    });
    let id = getID.join();
    this.rowSelectionObj = [];
    this.onOpenAction(`campaignedit/${id}`);
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false, operationTitle: "", operationAction: "" });
  };

  onRemoveSelectedConfirm = async () => {
    let response = await commonDelAPI("deleteCampaignRecordByID", this.rowSelectionObj);
    this.handleClose();
    this.getRefresh();
    return response;
  };

  onRemoveSelected = () => {
    if (typeof this.rowSelectionObj === "undefined") {
      this.setState({
        operationTitle: "Row Selection!",
        operationAction: "Please select record",
      });
      this.handleClickOpen();
      return true;
    } else {
      var count = Object.keys(this.rowSelectionObj).length;
      if (count === 0) {
        this.setState({
          operationTitle: "Row Selection!",
          operationAction: "Please select record!",
        });
        this.handleClickOpen();
        return true;
      } else {
        this.setState({
          operationTitle: "Delete Selection!",
          operationAction: "Want to delete?",
          isDelete: true,
        });
        this.handleClickOpen();
        return true;
      }
    }
  };

  onChangeItem = (e) => {
    e.preventDefault();
    partialState[e.target.name] = e.target.value;
    this.setState(partialState);
  }

  reSet = () => {
    var partialState = {
      name: '',
    };
    this.setState(partialState);
  }



  render() {
    let gridOptions = {
      defaultColDef: {
        flex: 1,
        sortable: true,
        filter: true,
        resizable: true,
        autoHeight: false,
        editable: true,
        floatingFilter: false,
      },
      localeText: AG_GRID_LOCALE_EN,
      columnDefs: this.state.properties.columnDefs,
      cacheBlockSize: this.state.paginationPageSize,
      paginationPageSize: this.state.paginationPageSize,
      rowBuffer: 0,
      rowModelType: "serverSide",
      serverSideStoreType: "partial",
      maxBlocksInCache: 2,
      rowSelection: "multiple",
      pagination: true,
      onGridReady: this.onGridReady,
      onSelectionChanged: this.onSelectionChanged,
    };

    let rowClassRules = {
      rowOdd: function (params) {
        return params.rowIndex % 2 === 0;
      },
      rowEven: function (params) {
        return params.rowIndex % 2 === 1;
      },
    };

    let { open, operationTitle, operationAction, isDelete } = this.state;
    if (sessionStorage.length === 0) {
      return <Navigate to="/" replace={true} />;
    }
    return (
      <div className="ag-theme-material gridProperties">
        <div>
          <Dialog
            open={open}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            id="messageDialog"
          >
            <DialogTitle id="alert-dialog-title">{operationTitle}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {operationAction}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              {isDelete === true ? (
                <Button
                  onClick={this.handleClose}
                  className="btn-outline-white"
                >
                  No
                </Button>
              ) : (
                ""
              )}
              {isDelete === true ? (
                <Button
                  onClick={this.onRemoveSelectedConfirm}
                  className="btn-primary"
                  autoFocus
                >
                  Yes
                </Button>
              ) : (
                <Button
                  onClick={this.handleClose}
                  autoFocus
                  className="btn-outline-white"
                >
                  No
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </div>
        <Container
          maxWidth="xl"
          sx={{ marginTop: 2 }}
          className="breadcrumbs-section"
        >
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <Custombreadcrumb hierarchy={this.state.hierarchy} />
            </Grid>
            <Grid item md={6} xs={12} className="btn-section">
              <Typography
                variant="h8"
                gutterBottom
                sx={{
                  color: "#952424",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  gap: "10px",
                  flexWrap: "wrap",
                }}
                justify="flex-end"
              >
                <Button
                  startIcon={<AddIcon />}
                  variant="contained"
                  size="small"
                  sx={{ mb: "5px", mt: "5px" }}
                  onClick={() => this.onOpenAction("addcampaign")}
                >
                  Add
                </Button>
                <Button
                  startIcon={<EditIcon />}
                  variant="contained"
                  size="small"
                  sx={{ mb: "5px", mt: "5px" }}
                  onClick={() => this.onEditRow("campaignedit")}
                >
                  Edit
                </Button>
                <Button
                  startIcon={<RemoveIcon />}
                  variant="contained"
                  size="small"
                  sx={{ mb: "5px", mt: "5px" }}
                  onClick={() => this.onRemoveSelected()}
                >
                  Remove
                </Button>
                <Button
                  startIcon={<ReplayIcon />}
                  variant="contained"
                  size="small"
                  sx={{ mb: "5px", mt: "5px" }}
                  onClick={() => this.getRefresh()}
                >
                  Reload
                </Button>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-search"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                  </svg>{" "}
                  &nbsp;&nbsp;
                  <span>Search</span>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    borderTop: "1px solid #ddd",
                  }}
                >
                  <Box
                    className="formBox"
                    component="form"
                    sx={{
                      boxShadow: "none",
                      padding: "0 5px",
                      marginTop: "0",
                    }}
                    onSubmit={this.getRecord}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={4}>
                        <TextField
                          margin="normal"
                          fullWidth
                          id="name"
                          label="Campaign Name"
                          name="name"
                          value={this.state.name}
                          onChange={this.onChangeItem}
                          autoComplete="none"
                          autoFocus
                          type="text"
                          size="small"
                        />
                      </Grid>
                      <Grid
                        xs={12}
                        className="btnSections"
                        justifyContent="center"
                        display="flex"
                        alignItems="center"
                        sx={{
                          gap: "20px",
                          padding: "10px 15px",
                        }}
                      >
                        <Button
                          type="submit"
                          fullWidth
                          className="btn btn-primary"
                          sx={{
                            mt: 1,
                            mb: 1,
                            height: "35px",
                            padding: "5px 20px",
                            width: "100px",
                          }}
                          size="small"
                        >
                          Search
                        </Button>
                        <Button
                          type="reset"
                          fullWidth
                          className="btn btn-outline-white"
                          sx={{
                            mt: 1,
                            mb: 1,
                            height: "35px",
                            width: "100px",
                            padding: "5px 20px",
                          }}
                          size="small"
                          onClick={this.reSet}
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Container>
        <AgGridReact
          className="table-structure"
          gridOptions={gridOptions}
          rowModelType="serverSide"
          serverSideStoreType="full"
          rowClassRules={rowClassRules}
          suppressRowClickSelection={true}
          suppressHorizontalScroll={true}
          alwaysShowVerticalScroll={true}
        />
      </div>
    );
  }
}

export default withRouter(CampaignData);
