import React, { Component } from "react";
import { commonPostAPI } from "../../commoncall/commoncall";
import { Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { withRouter } from "../withRouter";
import Custombreadcrumb from "../Custombreadcrumb";
import { Button, Grid, Select, MenuItem } from "@mui/material";
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import ListIcon from '@mui/icons-material/List';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};

var partialState = {
  template_uuid: "",
  template_name: "",
  form_title: "",
  tools_label_name: [],
  tools_label_value: [],
  tools_type_name: [],
  tools_type_value: [],
  tools_type_options_label: [],
  tools_type_options_value: [],
  option_caption_name: [],
  option_caption_value: [],
  option_caption_option_name: [],
  option_caption_option_value: [],
  currentOptionIndex: "",
};
class TemplateaddData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {
        template_name: "Please enter name.",
        form_title: "Please enter form title",
      },
      alertMessage: "",
      tylesList: [
        {
          id: 1,
          value: "Text"
        },
        {
          id: 2,
          value: "Textarea"
        },
        {
          id: 3,
          value: "Single Select"
        },
        {
          id: 4,
          value: "Multi select"
        },
        {
          id: 5,
          value: "Date & Time"
        },
      ],
      template_uuid: "",
      template_name: "",
      form_title: "",
      tools_label_name: [],
      tools_label_value: [],
      tools_type_name: [],
      tools_type_value: [],
      tools_type_options_label: [],
      tools_type_options_value: [],
      option_caption_name: [],
      option_caption_value: [],
      option_caption_option_name: [],
      option_caption_option_value: [],
      hierarchy: [
        {
          label: "Home",
          action: "",
        },
        {
          label: "CRM Template",
          action: "crmtemplate",
        },
        {
          label: "Edit CRM Template",
          action: "",
        },
      ],
      open: false,
      currentOptionIndex: "",
    };
  }

  componentDidMount = async () => {
    var url = window.location.pathname;
    var id = url.substring(url.lastIndexOf("/") + 1);
    this.getDataByID(id);
  }

  getDataByID = async (id) => {
    let responseTemplate = await commonPostAPI("getRecordTemplatesByID", { id: id });
    let responseTemplateList = await commonPostAPI("getRecordTemplateListsByID", { id: id });
    let errors = this.state.errors;
    Object.keys(responseTemplate).forEach(function (key) {
      switch (key) {
        case "template_name":
          errors.template_name =
            typeof responseTemplate[key] !== "undefined" &&
              responseTemplate[key] !== "" &&
              responseTemplate[key] != null
              ? ""
              : "Please enter name.";
          break;
        case "form_title":
          errors.form_title =
            typeof responseTemplate[key] !== "undefined" &&
              responseTemplate[key] !== "" &&
              responseTemplate[key] != null
              ? ""
              : "Please enter form title.";
          break;
        default:
          break;
      }
    });

    let current_tools_label_name = (responseTemplateList && responseTemplateList.length > 0) ? responseTemplateList.map((item, index) => {
      return `Label_Name_${index}`;
    }) : [];

    let current_tools_label_value = (responseTemplateList && responseTemplateList.length > 0) ? responseTemplateList.map((item, index) => {
      return item.tools_label;
    }) : [];

    let current_tools_type_name = (responseTemplateList && responseTemplateList.length > 0) ? responseTemplateList.map((item, index) => {
      return `Tools_Name_${index}`;
    }) : [];

    let current_tools_type_value = (responseTemplateList && responseTemplateList.length > 0) ? responseTemplateList.map((item, index) => {
      return parseInt(item.tools_type);
    }) : [];

    let current_tools_type_options_label = (responseTemplateList && responseTemplateList.length > 0) ? responseTemplateList.map((item, index) => {
      return JSON.parse(item.tools_options_label);
    }) : [];

    let current_tools_type_options_value = (responseTemplateList && responseTemplateList.length > 0) ? responseTemplateList.map((item, index) => {
      return JSON.parse(item.tools_options_value);
    }) : [];

    var dbData = {
      errors: errors,
      template_uuid: id,
      template_name: responseTemplate.template_name,
      form_title: responseTemplate.form_title,
      tools_label_name: current_tools_label_name,
      tools_label_value: current_tools_label_value,
      tools_type_name: current_tools_type_name,
      tools_type_value: current_tools_type_value,
      tools_type_options_label: current_tools_type_options_label,
      tools_type_options_value: current_tools_type_options_value,
    };


    partialState = dbData;

    this.setState(partialState);
  };

  saveData = async (event) => {
    event.preventDefault();
    if (validateForm(this.state.errors)) {
      this.setState({ alertMessage: "" });
      let requestparams = {
        template_uuid: this.state.template_uuid,
        template_name: this.state.template_name,
        form_title: this.state.form_title,
        tools_label_name: this.state.tools_label_name,
        tools_label_value: this.state.tools_label_value,
        tools_type_name: this.state.tools_type_name,
        tools_type_value: this.state.tools_type_value,
        tools_type_options_label: this.state.tools_type_options_label,
        tools_type_options_value: this.state.tools_type_options_value,
        domain_uuid: sessionStorage.getItem("domain_uuid"),
      };
      let response = await commonPostAPI("editcrmtemplate", requestparams);
      if (response.template_name && response.template_name !== "") {
        this.setState({ alertMessage: <div>Name already exists.</div> });
      } else {
        this.onOpenAction("crmtemplate");
      }
    } else {
      this.setState({
        alertMessage: <div>Please enter required parameters.</div>,
      });
    }
  };

  onOpenAction = (action) => {
    this.props.navigate(`/${action}`);
  };

  addTools = () => {
    let {
      tools_label_name,
      tools_label_value,
      tools_type_name,
      tools_type_value,
      tools_type_options_label,
      tools_type_options_value,
    } = this.state;

    let count = tools_label_name.length;
    let name = "";

    let newLabelName = [];
    let newLabelValue = [];
    name = `Label_Name_${count}`;
    newLabelName = tools_label_name
    newLabelName.push(name)
    newLabelValue = tools_label_value;
    newLabelValue.push("");

    let newToolsName = [];
    let newToolsValue = [];
    name = `Tools_Name_${count}`;
    newToolsName = tools_type_name;
    newToolsName.push(name);
    newToolsValue = tools_type_value;
    newToolsValue.push("0");

    let newOptionsName = [];
    let newOptionsValue = [];
    newOptionsName = tools_type_options_label;
    newOptionsName.push("");
    newOptionsValue = tools_type_options_value;
    newOptionsValue.push("");

    this.setState({
      tools_label_name: newLabelName,
      tools_label_value: newLabelValue,
      tools_type_name: newToolsName,
      tools_type_value: newToolsValue,
      tools_type_options_label: newOptionsName,
      tools_type_options_value: newOptionsValue,
    });

  };

  removeTools = (index) => {
    this.setState({
      tools_label_name: this.state.tools_label_name.filter(function (items, idx) {
        return idx !== index;
      }),
      tools_label_value: this.state.tools_label_value.filter(function (items, idx) {
        return idx !== index;
      }),
      tools_type_name: this.state.tools_type_name.filter(function (items, idx) {
        return idx !== index;
      }),
      tools_type_value: this.state.tools_type_value.filter(function (items, idx) {
        return idx !== index;
      }),
      tools_type_options_label: this.state.tools_type_options_label.filter(function (items, idx) {
        return idx !== index;
      }),
      tools_type_options_value: this.state.tools_type_options_value.filter(function (items, idx) {
        return idx !== index;
      }),
    });
  };

  onChangeItem = (e) => {
    e.preventDefault();
    partialState[e.target.name] = e.target.value;

    let errors = this.state.errors;
    switch (e.target.name) {
      case "template_name":
        errors.template_name =
          e.target.value.length < 1 ? "Please enter name." : "";
        break;
      case "form_title":
        errors.form_title =
          e.target.value.length < 1 ? "Please enter title." : "";
        break;
      default:
        break;
    }

    this.setState({ errors, [e.target.name]: e.target.value });
    this.setState(partialState);
  };

  onChangeLabelAction = async (i, e) => {
    let { tools_label_value } = this.state;
    let old = [...tools_label_value];
    old[i] = e.target.value;
    this.setState({
      tools_label_value: old,
    });
  };

  onChangeTypeAction = async (i, e) => {
    let { tools_type_value } = this.state;
    let old = [...tools_type_value];
    old[i] = e.target.value;
    this.setState({
      tools_type_value: old,
    });
  };

  handleOpen = (index) => {
    this.setState({
      open: true,
      currentOptionIndex: index,
      option_caption_name: [],
      option_caption_value: [],
      option_caption_option_name: [],
      option_caption_option_value: [],
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
      currentOptionIndex: "",
      option_caption_name: [],
      option_caption_value: [],
      option_caption_option_name: [],
      option_caption_option_value: [],
    });
  };

  addSubOptions = () => {
    let {
      option_caption_name,
      option_caption_value,
      option_caption_option_name,
      option_caption_option_value,
    } = this.state;

    let count = option_caption_name.length;
    let name = "";

    let newCaptionName = [];
    let newCaptionValue = [];
    name = `Caption_Name_${count}`;
    newCaptionName = option_caption_name
    newCaptionName.push(name)
    newCaptionValue = option_caption_value;
    newCaptionValue.push("");

    let newCaptionOptionName = [];
    let newCaptionOptionValue = [];
    name = `Caption_Option_Name_${count}`;
    newCaptionOptionName = option_caption_option_name;
    newCaptionOptionName.push(name);
    newCaptionOptionValue = option_caption_option_value;
    newCaptionOptionValue.push("");

    this.setState({
      option_caption_name: newCaptionName,
      option_caption_value: newCaptionValue,
      option_caption_option_name: newCaptionOptionName,
      option_caption_option_value: newCaptionOptionValue,
    });

  };

  removeSubOptions = (index) => {
    this.setState({
      option_caption_name: this.state.option_caption_name.filter(function (items, idx) {
        return idx !== index;
      }),
      option_caption_value: this.state.option_caption_value.filter(function (items, idx) {
        return idx !== index;
      }),
      option_caption_option_name: this.state.option_caption_option_name.filter(function (items, idx) {
        return idx !== index;
      }),
      option_caption_option_value: this.state.option_caption_option_value.filter(function (items, idx) {
        return idx !== index;
      }),
    });
  };

  onChangeOptionCaptionNameAction = async (i, e) => {
    let { option_caption_value } = this.state;
    let old = [...option_caption_value];
    old[i] = e.target.value;
    this.setState({
      option_caption_value: old,
    });
  };

  onChangeOptionCaptionValueAction = async (i, e) => {
    let { option_caption_option_value } = this.state;
    let old = [...option_caption_option_value];
    old[i] = e.target.value;
    this.setState({
      option_caption_option_value: old,
    });
  };

  submitOptionAction = async () => {
    let {
      currentOptionIndex,
      tools_type_options_label,
      tools_type_options_value,
      option_caption_value,
      option_caption_option_value,

    } = this.state;

    let old1 = [...tools_type_options_label];
    old1[currentOptionIndex] = option_caption_value;

    let old2 = [...tools_type_options_value];
    old2[currentOptionIndex] = option_caption_option_value;

    this.setState({
      open: false,
      tools_type_options_label: old1,
      tools_type_options_value: old2,
      currentOptionIndex: "",
      option_caption_name: [],
      option_caption_value: [],
      option_caption_option_name: [],
      option_caption_option_value: [],
    });

  }

  render() {
    if (sessionStorage.length === 0) {
      return <Navigate to="/" replace={true} />;
    }

    const theme = createTheme();
    let {
      errors,
      template_name,
      form_title,
      tylesList,
      tools_label_name,
      tools_label_value,
      tools_type_name,
      tools_type_value,
      option_caption_name,
      option_caption_value,
      option_caption_option_name,
      option_caption_option_value,
      alertMessage,
      open,
    } = this.state;

    let toolsArray = [];
    for (var i = 0; i < tools_label_name.length; i++) {
      let index = i + 1;
      let currentIndex = i;
      toolsArray.push(
        <Grid container xs={12}>
          <Grid Item xs={1} style={{ marginTop: 0, marginBottom: 0 }}>
            <h4>( {index} )</h4>
          </Grid>
          <Grid Item xs={2}>
            <TextField
              margin="0"
              required
              fullWidth
              labelId={tools_label_name[i]}
              value={tools_label_value[i]}
              id={tools_label_name[i]}
              name={tools_label_name[i]}
              onChange={this.onChangeLabelAction.bind(this, i)}
              autoComplete="none"
              type="text"
              size="small"
            />
          </Grid>
          <Grid Item xs={1}>&nbsp;</Grid>
          <Grid Item xs={3}>
            <Select
              fullWidth
              labelId={tools_type_name[i]}
              value={tools_type_value[i]}
              id={tools_type_name[i]}
              name={tools_type_name[i]}
              onChange={this.onChangeTypeAction.bind(this, i)}
              size="small"
              autoComplete="none"
            >
              {tylesList.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.value}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid Item xs={1}>&nbsp;</Grid>
          <Grid Item xs={3} style={{ marginTop: 8, marginBottom: 0 }}>
            {(tools_type_value[i] && (tools_type_value[i] === 3 || tools_type_value[i] === 4)) ? <ListIcon onClick={() => this.handleOpen(currentIndex)} /> : ""}
          </Grid>
          <Grid Item xs={1} style={{ marginTop: 8, marginBottom: 0 }}>
            <DeleteIcon onClick={() => this.removeTools(currentIndex)} />
          </Grid>
        </Grid>)
    }

    let optionsArray = [];
    for (i = 0; i < option_caption_name.length; i++) {
      let index = i + 1;
      let currentIndex = i;
      optionsArray.push(
        <Grid container xs={12} style={{ marginTop: 5, marginBottom: 0 }}>
          <Grid Item xs={1} style={{ marginTop: 5, marginBottom: 0 }}>
            {index} ]
          </Grid>
          <Grid Item xs={4}>
            <TextField
              margin="0"
              required
              fullWidth
              labelId={option_caption_name[i]}
              value={option_caption_value[i]}
              id={option_caption_name[i]}
              name={option_caption_name[i]}
              onChange={this.onChangeOptionCaptionNameAction.bind(this, i)}
              autoComplete="none"
              type="text"
              size="small"
            />
          </Grid>
          <Grid Item xs={1}>&nbsp;</Grid>
          <Grid Item xs={4}>
            <TextField
              margin="0"
              required
              fullWidth
              labelId={option_caption_option_name[i]}
              value={option_caption_option_value[i]}
              id={option_caption_option_name[i]}
              name={option_caption_option_name[i]}
              onChange={this.onChangeOptionCaptionValueAction.bind(this, i)}
              autoComplete="none"
              type="text"
              size="small"
            />
          </Grid>
          <Grid Item xs={1}>&nbsp;</Grid>
          <Grid Item xs={1} style={{ marginTop: 8, marginBottom: 0 }}>
            <DeleteIcon onClick={() => this.removeSubOptions(currentIndex)} />
          </Grid>
        </Grid>)
    }

    return (
      <div className="submit-form formElements">
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xl">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "15px 0",
              }}
            >
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Custombreadcrumb hierarchy={this.state.hierarchy} />
                </Grid>
              </Grid>
              <Box className="formBox">
                <Box
                  component="form"
                  onSubmit={this.saveData}
                  noValidate
                  sx={{ mt: 2, width: "100%", padding: "0 15px" }}
                >
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={4}>
                      <label>Name</label>
                      <TextField
                        margin="0"
                        required
                        fullWidth
                        id="template_name"
                        name="template_name"
                        value={template_name ?? ""}
                        onChange={this.onChangeItem}
                        autoComplete="none"
                        type="text"
                        size="small"
                      />
                      {errors.template_name.length > 0 ? (
                        <Stack className="error-show" spacing={2}>
                          {errors.template_name}
                        </Stack>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item md={4}>
                      <label>Title</label>
                      <TextField
                        margin="0"
                        required
                        fullWidth
                        id="form_title"
                        name="form_title"
                        value={form_title ?? ""}
                        onChange={this.onChangeItem}
                        autoComplete="none"
                        type="text"
                        size="small"
                      />
                      {errors.form_title.length > 0 ? (
                        <Stack className="error-show" spacing={2}>
                          {errors.form_title}
                        </Stack>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>

                  <Grid container xs={12}>&nbsp;</Grid>

                  <Grid container xs={12}>
                    <Grid Item xs={1} style={{ marginTop: 14, marginBottom: 25 }}>
                      <AddBoxIcon onClick={() => this.addTools()} />
                    </Grid>
                    <Grid Item xs={2}>
                      <h4>Label</h4>
                    </Grid>
                    <Grid Item xs={1}>&nbsp;</Grid>
                    <Grid Item xs={3}>
                      <h4>Type</h4>
                    </Grid>
                    <Grid Item xs={1}>&nbsp;</Grid>
                    <Grid Item xs={3}>
                      <h4>Set Options</h4>
                    </Grid>
                    <Grid Item xs={1}>
                      <h4>Action</h4>
                    </Grid>
                  </Grid>

                  {toolsArray}

                  {alertMessage ? (
                    <Stack className="error-show" spacing={2}>
                      {alertMessage}
                    </Stack>
                  ) : (
                    ""
                  )}
                  <Grid container xs={12}>&nbsp;</Grid>
                  <Grid container justifyContent="flex-end" xs={12}>
                    <table className="form-btns">
                      <tr>
                        <td>
                          <Button
                            type="submit"
                            fullWidth
                            className="btn btn-primary"
                            sx={{
                              mt: 1,
                              mb: 1,
                              height: "35px",
                              padding: "5px 20px",
                            }}
                            size="small"
                          >
                            Submit
                          </Button>
                        </td>
                        <td>
                          <Button
                            type="button"
                            fullWidth
                            className="btn btn-outline-white"
                            sx={{
                              mt: 1,
                              mb: 1,
                              height: "35px",
                              padding: "5px 20px",
                              marginLeft: "10px",
                            }}
                            size="small"
                            onClick={() => this.onOpenAction("crmtemplate")}
                          >
                            Back
                          </Button>
                        </td>
                      </tr>
                    </table>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
        <div>
          <Dialog
            open={open}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            id="messageDialog"
            fullWidth
          >
            <DialogTitle id="alert-dialog-title">Set Options</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Grid container xs={12}>
                  <Grid Item xs={1} style={{ marginTop: 20, marginBottom: 25 }}>
                    <AddBoxIcon onClick={() => this.addSubOptions()} />
                  </Grid>
                  <Grid Item xs={4}>
                    <h6>Label</h6>
                  </Grid>
                  <Grid Item xs={1}>&nbsp;</Grid>
                  <Grid Item xs={4}>
                    <h6>Value</h6>
                  </Grid>
                  <Grid Item xs={1}>&nbsp;</Grid>
                  <Grid Item xs={1}>
                    <h6>Action</h6>
                  </Grid>
                </Grid>
                {optionsArray}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => this.submitOptionAction()}
                className="btn-primary"
                autoFocus
              >
                Yes
              </Button>
              <Button
                onClick={this.handleClose}
                autoFocus
                className="btn-outline-white"
              >
                No
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  }
}

export default withRouter(TemplateaddData);
