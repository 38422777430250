import { useEffect, useRef, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Paper,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
// import ImageIcon from "@mui/icons-material/Image";
import SendIcon from "@mui/icons-material/Send";
import { format, parseISO } from "date-fns";
import socketIO from "socket.io-client";

import CustombreadcrumbFun from "../Custombreadcrumb";
import AppConfig from "../../Constants/AppConfig";
import {
  useUserChatController,
  setUnreadMessageCount,
  setUnreadMessageCountData,
} from "../Context/UserChatContext";

import dotsLoading from "../../Assets/bgimage/dots-loading.gif";

const hierarchy = [
  {
    label: "Home",
    action: "",
  },
  {
    label: "Chat",
    action: "chat",
  },
];

const userStatusColorCode = {
  availeble: "#44b700",
  away: "#FFb700",
  dnd: "#FF0000",
  offline: "#808080",
};

const Chat = () => {
  const [controller, dispatch] = useUserChatController();
  const { userUnreadMsgCountData } = controller;

  const LoggedInUserId = sessionStorage.getItem("user_uuid");
  const LoggedInUserName = sessionStorage.getItem("Username");
  const LoggedInUserPresence = sessionStorage.getItem("user_presence");
  const LoggedInUserDomainId = sessionStorage.getItem("domain_uuid");

  const theme = createTheme();
  const ref = useRef();

  const [socket, setSocket] = useState(null);
  const [selectedUser, setSelectedUser] = useState({});
  const [userList, setUserList] = useState([]);
  const [userMessages, setUserMessages] = useState({});
  const [messageInput, setMessageInput] = useState("");
  const [error, setError] = useState("");
  const [typing, setTyping] = useState({});

  useEffect(() => {
    if (LoggedInUserId !== undefined) {
      const sessionID = sessionStorage.getItem("token") ?? "";
      const newSocket = socketIO.connect(AppConfig.webSocketUrl, {
        auth: {
          sessionID,
          username: LoggedInUserName,
          userID: LoggedInUserId,
        },
        extraHeaders: {
          "react-client": "react-client",
        },
      });
      setSocket(newSocket);

      return () => newSocket.close();
    }
  }, []);

  useEffect(() => {
    if (socket !== null) {
      socket.on("session", ({ sessionID, userID }) => {
        // attach the session ID to the next reconnection attempts
        socket.auth = { sessionID, userID };
      });
    }
  }, [socket]);

  useEffect(() => {
    //get chat user list
    if (socket !== null) {
      let domain_uuid = sessionStorage.getItem("domain_uuid");
      socket.emit("get-users", { domain_uuid, user_uuid: LoggedInUserId });

      socket.on("get-users", (data) => {
        setUserList(data);
      });
    }
  }, [socket]);

  useEffect(() => {
    if (socket !== null) {
      socket.on("userStatusUpdated", (data) => {
        userList.map((user) => {
          if (user.user_uuid === data.user_uuid) {
            user.user_presence = data.status;
          }
          return user;
        });
        setUserList([...userList]);
      });
    }
  }, [socket]);

  useEffect(() => {
    if (Object.keys(selectedUser) && selectedUser?.messages?.length > 0) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [selectedUser, typing]);

  const handleInputChange = (e) => {
    e.preventDefault();
    setMessageInput(e.target.value);

    socket.emit("typing", {
      senderID: LoggedInUserId,
      recieverID: selectedUser.user_uuid,
      isTyping: e.target.value === "" ? false : true,
    });
  };

  const handleUserSelection = (e, user) => {
    e.preventDefault();

    let index = userList.findIndex((u) => u.user_uuid === user.user_uuid);
    if (index !== -1) {
      user.messages = userList[index].messages;
    }
    setSelectedUser({ ...user });

    const countObj = userUnreadMsgCountData.find((e) => {
      return e.sender_uuid === user.user_uuid;
    });

    if (countObj !== undefined && countObj.unread_message_count > 0) {
      socket.emit("update-message-status", {
        fromID: user.user_uuid,
        recieverID: LoggedInUserId,
        is_read: true,
      });
    }
  };

  const handleMessageSend = (e) => {
    e.preventDefault();

    socket.emit(
      "send-message",
      {
        ...selectedUser,
        message: messageInput,
        recieverID: selectedUser.user_uuid,
        senderID: LoggedInUserId,
        senderDomainId: LoggedInUserDomainId,
      },
      (error) => {
        if (error !== "") {
          //return error;
          setError(error);
        }
        setMessageInput("");
      }
    );

    socket.emit("typing", {
      senderID: LoggedInUserId,
      recieverID: selectedUser.user_uuid,
      isTyping: false,
    });

    socket.emit("new-message-count", { userID: selectedUser.user_uuid });
  };

  useEffect(() => {
    if (socket !== null) {
      socket.on("received-messages", ({ messages, recieverID, senderID }) => {
        let obj = { messages, recieverID, senderID };
        setUserMessages({ ...obj });
      });
    }
  }, [socket]);

  useEffect(() => {
    if (socket !== null) {
      if (selectedUser.user_uuid === userMessages.senderID) {
        //update message status read
        socket.emit("update-message-status", {
          fromID: userMessages.senderID,
          recieverID: userMessages.recieverID,
          is_read: true,
        });
      }

      if (
        Object.keys(selectedUser).length > 0 &&
        (selectedUser.user_uuid === userMessages.senderID ||
          selectedUser.user_uuid === userMessages.recieverID)
      ) {
        selectedUser.messages = userMessages.messages;
        setSelectedUser({ ...selectedUser });
      }

      let index = userList.findIndex(
        (u) => u.user_uuid === userMessages.senderID
      );
      if (index !== -1) {
        userList[index].messages = userMessages.messages;
      }

      let recIndex = userList.findIndex(
        (u) => u.user_uuid === userMessages.recieverID
      );
      if (recIndex !== -1) {
        userList[recIndex].messages = userMessages.messages;
      }
      userList.sort((a, b) => b.messages.length - a.messages.length);

      userList.sort((a, b) => {
        let sortbMsgInx = b.messages.length > 0 ? b.messages.length - 1 : 0;
        let sortAMsgInx = a.messages.length > 0 ? a.messages.length - 1 : 0;

        if (
          b.messages[sortbMsgInx]?.chat_created_at === undefined &&
          a.messages[sortAMsgInx]?.chat_created_at === undefined
        ) {
          return 0;
        } else if (b.messages[sortbMsgInx]?.chat_created_at === undefined) {
          return a;
        } else if (a.messages[sortAMsgInx]?.chat_created_at === undefined) {
          return b;
        } else {
          return b.messages[sortbMsgInx]?.chat_created_at >
            a.messages[sortAMsgInx]?.chat_created_at
            ? 1
            : -1;
        }
      });

      setUserList([...userList]);
    }
  }, [userMessages]);

  useEffect(() => {
    if (socket !== null) {
      socket.on("new-message-count", (res) => {
        let count = 0;
        res.forEach((data) => {
          count += parseInt(data.unread_message_count);
        });
        setUnreadMessageCount(dispatch, count);
        setUnreadMessageCountData(dispatch, res);
      });
    }
  }, [socket]);

  useEffect(() => {
    if (socket !== null) {
      socket.on("typing", (data) => {
        setTyping({ ...data });

        setTimeout(() => {
          setTyping("");
        }, 4000);
      });

      return () => {
        socket.off("typing");
      };
    }
  }, [socket]);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xl">
        <CssBaseline />

        <Box sx={{ display: "flex" }}>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <CustombreadcrumbFun hierarchy={hierarchy} />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ display: "flex", padding: "10px 0" }}>
          <Grid
            container
            component={Paper}
            sx={{ width: "100%", height: "75vh" }}
          >
            <Grid item xs={3} sx={{ borderRight: "1px solid #e0e0e0" }}>
              <List>
                <ListItem key="RemySharp">
                  <GetUserAvatar
                    username={LoggedInUserName}
                    userPresence={LoggedInUserPresence}
                  />
                  <ListItemText
                    primary={
                      LoggedInUserName.charAt(0).toUpperCase() +
                      LoggedInUserName.slice(1)
                    }
                  ></ListItemText>
                </ListItem>
              </List>
              <Divider />
              <List>
                {userList.map((user, index) => (
                  <ListItem key={user.user_uuid} disablePadding>
                    <ListItemButton
                      onClick={(e) => handleUserSelection(e, user)}
                      selected={
                        Object.keys(selectedUser).length === 0
                          ? false
                          : user.user_uuid === selectedUser.user_uuid
                      }
                    >
                      <GetUserAvatar
                        username={user?.displayname}
                        userPresence={user?.user_presence}
                      />
                      <ListItemText
                        primary={user.displayname}
                        secondary={
                          typing?.isTyping &&
                            typing?.senderID === user.user_uuid &&
                            selectedUser?.user_uuid !== typing?.senderID ? (
                            <img src={dotsLoading} />
                          ) : (
                            user?.message
                          )
                        }
                      />
                      <span>
                        <UserMessageCount
                          userUnreadMsgCountData={userUnreadMsgCountData}
                          user_uuid={user.user_uuid}
                        />
                      </span>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item xs={9}>
              <AppBar
                position="static"
                sx={{
                  backgroundColor: "#1976d2 !important",
                }}
              >
                <Toolbar>
                  {Object.keys(selectedUser).length > 0 ? (
                    <GetUserAvatar
                      username={selectedUser?.displayname}
                      userPresence={selectedUser?.user_presence}
                    />
                  ) : (
                    ""
                  )}
                  <Typography variant="h6" noWrap component="div">
                    {selectedUser?.displayname}
                  </Typography>
                </Toolbar>
              </AppBar>
              <Box
                component="main"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "66vh",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                    bgcolor: "background.default",
                    p: 3,
                    maxHeight: "55vh",
                    overflowY: "auto",
                  }}
                >
                  <Container maxWidth="lg">
                    {Object.keys(selectedUser).length > 0 &&
                      selectedUser?.messages?.length > 0 ? (
                      selectedUser.messages.map((message) => {
                        let isMe = message.sender_uuid === LoggedInUserId;
                        return (
                          <Box
                            key={message.user_chat_uuid}
                            sx={{ display: "flex" }}
                          >
                            {!isMe ? (
                              <Avatar
                                sx={{
                                  marginRight: "10px",
                                  marginTop: "15px",
                                }}
                              >
                                {/* <ImageIcon /> */}
                                {selectedUser.displayname?.substring(0, 1)}
                              </Avatar>
                            ) : (
                              ""
                            )}
                            <Box sx={{ display: "flex", width: "100%" }}>
                              <Box
                                sx={{
                                  width: "100%",
                                  flexDirection: "column",
                                  display: "flex",
                                  justifyContent: isMe
                                    ? "flex-end"
                                    : "flex-start",
                                  mb: 3,
                                  alignItems: isMe ? "flex-end" : "flex-start",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "0.7rem",
                                    color: "#808080",
                                    minWidth: "7%",
                                  }}
                                >
                                  {!isMe ? message.sendername + " " : ""}{" "}
                                  {format(
                                    parseISO(message.chat_created_at),
                                    "M/dd hh:mm a"
                                  )}
                                </Typography>

                                <Paper
                                  variant="outlined"
                                  sx={{
                                    p: 1,
                                    backgroundColor: isMe
                                      ? "secondary.light"
                                      : "primary.light",
                                    minWidth: "7%",
                                  }}
                                >
                                  <Typography variant="body1">
                                    {message.chat_text}
                                  </Typography>
                                </Paper>
                              </Box>
                            </Box>
                          </Box>
                        );
                      })
                    ) : (
                      <InitialMessageLayout selectedUser={selectedUser} />
                    )}
                    <TypingComponent
                      selectedUser={selectedUser}
                      typing={typing}
                    />
                    <div ref={ref} />
                  </Container>
                </Box>
                <Box
                  component="footer"
                  sx={{
                    position: "fixed",
                    bottom: "51",
                    width: "60%",
                  }}
                >
                  {Object.keys(selectedUser).length > 0 ? (
                    <Container maxWidth="lg">
                      <Box
                        sx={{ p: 2, backgroundColor: "background.default" }}
                        component="form"
                        noValidate
                        autoComplete="none"
                        onSubmit={(e) => handleMessageSend(e)}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={11}>
                            <TextField
                              size="small"
                              fullWidth
                              placeholder="Type a message"
                              onChange={(e) => handleInputChange(e)}
                              value={messageInput}
                              autoFocus
                              inputRef={(input) => input && input.focus()}
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <Button
                              fullWidth
                              size="medium"
                              color="primary"
                              variant="contained"
                              endIcon={<SendIcon />}
                              type="submit"
                            >
                              Send
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </Container>
                  ) : (
                    ""
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

const GetUserAvatar = ({ username, userPresence = "offline" }) => {
  return (
    <ListItemAvatar>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        variant="dot"
        sx={{
          "& .MuiBadge-badge": {
            backgroundColor: userStatusColorCode[userPresence],
          },
        }}
      >
        <Avatar
          sx={{
            bgcolor: "#ddd",
            color: "#212b36",
            fontWeight: "600",
          }}
        >
          {username?.substring(0, 1).toUpperCase()}
        </Avatar>
      </Badge>
    </ListItemAvatar>
  );
};

const UserMessageCount = ({ userUnreadMsgCountData, user_uuid }) => {
  const countObj = userUnreadMsgCountData.find((e) => {
    return e.sender_uuid === user_uuid;
  });
  return countObj !== undefined ? countObj?.unread_message_count : "";
};

const InitialMessageLayout = ({ selectedUser }) => {
  return Object.keys(selectedUser).length > 0 ? (
    <Typography paragraph align="center">
      You're starting a new conversation. Type your first message below.
    </Typography>
  ) : (
    <Typography paragraph align="center">
      You're starting a new conversation. Select user and start the
      conversation.
    </Typography>
  );
};

const TypingComponent = ({ selectedUser, typing }) => {
  return (
    <>
      {Object.keys(selectedUser).length > 0 &&
        Object.keys(typing).length > 0 &&
        typing?.isTyping &&
        typing?.senderID === selectedUser.user_uuid ? (
        <Typography>
          {selectedUser.displayname + " " + "is typing..."}
        </Typography>
      ) : (
        ""
      )}
    </>
  );
};

export default Chat;
