import React, { Component } from "react";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { commonPostAPI } from "../../commoncall/commoncall";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "../../Assets/index.css";
import { withRouter } from "../withRouter";
import Agentlist from "./Agentlist";
import AllcallData from "./AllcallData";
import QueuecallData from "./QueuecallData";
import { Select, MenuItem } from "@mui/material";
import Container from "@mui/material/Container";
import Custombreadcrumb from "../Custombreadcrumb";
import Box from "@mui/material/Box";

let intervalDataQueueList = "";
class Callcentercall extends Component {
  constructor(props) {
    super(props);

    this.state = {
      queue_extension: "0",
      queue_list: [],
      serviceCalls: 0,
      waitingCalls: 0,
      abandonedCalls: 0,
      hierarchy: [
        {
          label: "Home",
          action: "",
        },
        {
          label: "Live Calls",
          action: "callcentercall",
        },
      ],
    };
  }

  onOpenAction = (action) => {
    this.props.navigate(`/${action}`);
  };

  componentDidMount = async () => {
    this.getRecord();
    clearInterval(intervalDataQueueList);
    intervalDataQueueList = setInterval(() => {
      this.getRecord();
    }, 5000);
  };

  getRecord = async () => {
    const domain_uuid = sessionStorage.getItem("domain_uuid");
    let response = await commonPostAPI("callcenterlist", {
      domain_uuid: domain_uuid,
    });
    if (response) {
      this.setState({ queue_list: response });
    }

    if (this.state.queue_extension && this.state.queue_extension !== '0') {
      const domain_name = sessionStorage.getItem("domain_name");
      let cmd = "";
      if (this.state.queue_extension !== "") {
        let fullqueuename = `${this.state.queue_extension}@${domain_name}`;
        cmd = `json {"command": "callcenter_config","data": {"arguments":"queue list members", "queue_name": "${fullqueuename}"}} `;
      } else {
        cmd = `json {"command": "callcenter_config","data": { "arguments": "member list" } } `;
      }

      let responseMemebers = await commonPostAPI("eslcommand", {
        command: cmd,
      });
      if (responseMemebers && responseMemebers.response && responseMemebers.response.length > 0) {
        let AbandonedList = [];
        let waitingList = [];
        let serviceList = [];
        let finalList = responseMemebers.response;
        if (this.state.queue_extension !== "") {
          AbandonedList = finalList.filter((item) => {
            return item.state === "Abandoned";
          });

          waitingList = finalList.filter((item) => {
            return item.state === "Waiting" || item.state === "Trying";
          });

          serviceList = finalList.filter((item) => {
            return item.state === "Answered";
          });
        }
        this.setState({
          rowData: finalList,
          abandonedCalls: AbandonedList.length,
          waitingCalls: waitingList.length,
          serviceCalls: serviceList.length,
        });
      } else {
        this.setState({ rowData: [] });
      }
    }
  }

  componentWillUnmount = () => {
    clearInterval(intervalDataQueueList);
  }

  queueChange = async (e) => {
    this.setState({ queue_extension: e.target.value });
    if (e.target.value !== "0") {
      const domain_name = sessionStorage.getItem("domain_name");
      let cmd = "";
      if (e.target.value !== "") {
        let fullqueuename = `${e.target.value}@${domain_name}`;
        cmd = `json {"command": "callcenter_config","data": {"arguments":"queue list members", "queue_name": "${fullqueuename}"}} `;
      } else {
        cmd = `json {"command": "callcenter_config","data": { "arguments": "member list" } } `;
      }

      let responseMemebers = await commonPostAPI("eslcommand", {
        command: cmd,
      });
      if (responseMemebers && responseMemebers.response && responseMemebers.response.length > 0) {
        let AbandonedList = [];
        let waitingList = [];
        let serviceList = [];
        let finalList = responseMemebers.response;
        if (this.state.queue_extension !== "") {
          AbandonedList = finalList.filter((item) => {
            return item.state === "Abandoned";
          });

          waitingList = finalList.filter((item) => {
            return item.state === "Waiting" || item.state === "Trying";
          });

          serviceList = finalList.filter((item) => {
            return item.state === "Answered";
          });
        }
        this.setState({
          rowData: finalList,
          abandonedCalls: AbandonedList.length,
          waitingCalls: waitingList.length,
          serviceCalls: serviceList.length,
        });
      } else {
        this.setState({ rowData: [] });
      }
    }
  };

  render() {
    let {
      queue_list,
      queue_extension,
      serviceCalls,
      waitingCalls,
      abandonedCalls,
    } = this.state;

    const Item = styled(Paper)(({ theme }) => ({
      backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
      ...theme.typography.body2,
      padding: theme.spacing(1),
      textAlign: "center",
      color: theme.palette.text.secondary,
    }));

    const ItemList = styled(Paper)(({ theme }) => ({
      backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
      ...theme.typography.body2,
      padding: theme.spacing(1),
      textAlign: "right",
      color: theme.palette.text.secondary,
    }));

    let allQueue = queue_extension === "0" ? true : false;
    return (
      <div>
        <Container
          maxWidth="xl"
          sx={{ marginTop: 2 }}
          className="breadcrumbs-section"
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={2}>
              {allQueue === false ? (
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{
                    boxShadow:
                      "0 2px 4px rgba(0, 0, 20, 0.08), 0 1px 2px rgba(0, 0, 20, 0.08)",
                    borderRadius: "0.5rem",
                    background: "#fff",
                    height: "100%",
                    maxWidth: "100% !important",
                    padding: "15px !important",
                    textAlign: "center",
                  }}
                >
                  Waiting Calls
                  <h1>
                    <strong>{waitingCalls}</strong>
                  </h1>
                </Grid>
              ) : ""}
            </Grid>
            <Grid item xs={12} md={2}>
              {allQueue === false ? (
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{
                    boxShadow:
                      "0 2px 4px rgba(0, 0, 20, 0.08), 0 1px 2px rgba(0, 0, 20, 0.08)",
                    borderRadius: "0.5rem",
                    background: "#fff",
                    height: "100%",
                    maxWidth: "100% !important",
                    padding: "15px !important",
                    textAlign: "center",
                  }}
                >
                  Service Calls
                  <h1>
                    <strong>{serviceCalls}</strong>
                  </h1>
                </Grid>
              ) : ""}
            </Grid>
            <Grid item xs={12} md={2}>
              {allQueue === false ? (
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{
                    boxShadow:
                      "0 2px 4px rgba(0, 0, 20, 0.08), 0 1px 2px rgba(0, 0, 20, 0.08)",
                    borderRadius: "0.5rem",
                    background: "#fff",
                    height: "100%",
                    maxWidth: "100% !important",
                    padding: "15px !important",
                    textAlign: "center",
                  }}
                >
                  Abandoned Calls
                  <h1>
                    <strong>{abandonedCalls}</strong>
                  </h1>
                </Grid>
              ) : ""}
            </Grid>
            <Grid item xs={12} md={3}>&nbsp;</Grid>
            <Grid item xs={12} md={2} sx={{ verticalAlign: 'bottom' }}>
              <ItemList
                sx={{
                  background: "transparent",
                  boxShadow: "none",
                }}
              >
                <Select
                  className="btn-primary"
                  labelId="queue_name"
                  value={queue_extension}
                  id="queue_name"
                  name="queue_name"
                  onChange={this.queueChange}
                  size="small"
                  autoComplete="none"
                >
                  <MenuItem value="0">All</MenuItem>
                  {queue_list.length > 0
                    ? queue_list.map((item) => {
                      return (
                        <MenuItem
                          key={item.queue_extension}
                          value={item.queue_extension}
                        >
                          {item.queue_name}
                        </MenuItem>
                      );
                    })
                    : ""}
                </Select>
              </ItemList>
            </Grid>
          </Grid>

        </Container>
        <Grid
          container
          className="callcenterdataset"
          spacing={2}
          sx={{
            mt: 1,
            mb: 1,
            height: "80%",
            width: "100%",
            background: "transparent",
            padding: "0 25px",
          }}
        >
          {allQueue === true ? (
            <Grid
              item
              md={allQueue === true ? 12 : 10}
              xs={12}
              sx={{
                mt: 1,
                mb: 1,
                height: allQueue === true ? "50%" : "100%",
              }}
            >
              <Item sx={{ mt: 1, mb: 1, height: "100%" }}>
                <AllcallData />
              </Item>
            </Grid>
          ) : (
            ""
          )}

          {allQueue === false ? (
            <Grid
              item
              xs={12}
              md={10}
              sx={{ mt: 1, mb: 1, height: "100%", paddingTop: 0 }}
            >
              <Item sx={{ mt: 1, mb: 1, height: "100%" }}>
                <QueuecallData queue_extension={queue_extension} />
              </Item>
            </Grid>
          ) : (
            ""
          )}

          {allQueue === false ? (
            <Grid item xs={12} md={2} sx={{ mt: 1, mb: 1, height: "100%" }}>
              <item
                className="caller-list-data"
                sx={{ mt: 1, mb: 1, height: "100%", background: "#fff" }}
              >
                <Agentlist queue_extension={queue_extension} />
              </item>
            </Grid>
          ) : (
            ""
          )}
        </Grid>

      </div>
    );
  }
}

export default withRouter(Callcentercall);
