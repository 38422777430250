import React, { Component } from "react";
import { commonPostAPI } from "../../commoncall/commoncall";
import { Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Custombreadcrumb from "../Custombreadcrumb";
import { Button, Grid, MenuItem, Tab, FormControlLabel, Checkbox } from "@mui/material";
import { withRouter } from "../withRouter";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import AppConfig from "../../Constants/AppConfig";

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};

var partialState = {};
class DepartmenteditData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {
        name: "Please enter name.",
      },
      alertMessage: "",
      department_id: '0',
      name: "",
      descriptions: "",
      currentStep: 1,
      moduleList: [],
      submoduleList: [],
      accessHierarchyList: [],
      acesssHierarchyHtml: "",
      hierarchy: [
        {
          label: "Home",
          action: "",
        },
        {
          label: "Department",
          action: "departments",
        },
        {
          label: "Edit Department",
          action: "",
        },
      ],
    };
  }

  componentWillMount = async () => {
    var url = window.location.pathname;
    var id = url.substring(url.lastIndexOf("/") + 1);
    let pageObj = { department_id: id };
    let responseModule = await commonPostAPI("modulelist", pageObj);
    this.setState({ moduleList: responseModule });
    let responseSubModule = await commonPostAPI("getAccessRecordByID", pageObj);
    this.setState({ submoduleList: responseSubModule });
    let accessHierarchyList = [];
    await responseSubModule.forEach(element => {
      accessHierarchyList.push({
        module_list_id: element.module_list_id,
        sub_module_id: element.sub_module_id,
        access_value: element.access_value,
        name: element.name,
        check_access_value: element.check_access_value,
      });
    });
    this.setState({ accessHierarchyList: accessHierarchyList, department_id: id });
    setTimeout(async () => {
      await this.handleTabChange(this.state.currentStep);
    }, 500);


    this.getDataByID(id);

  }

  getDataByID = async (id) => {
    let response = await commonPostAPI("getRecordById", { department_id: id, domain_uuid: sessionStorage.getItem('domain_uuid') });
    let errors = this.state.errors;
    Object.keys(response).forEach(function (key) {
      switch (key) {
        case "name":
          errors.name =
            typeof response[key] !== "undefined" &&
              response[key] !== "" &&
              response[key] != null
              ? ""
              : "Please enter name.";
          break;
        default:
          break;
      }
    });

    let dbData = {
      name: response.name,
      descriptions: response.description,
    };

    partialState = dbData;
    this.setState(partialState);
  };

  saveData = async (event) => {
    event.preventDefault();
    if (validateForm(this.state.errors)) {
      this.setState({ alertMessage: "" });
      let requestparams = {
        department_id: this.state.department_id,
        name: this.state.name,
        descriptions: this.state.descriptions,
        accessHierarchyList: this.state.accessHierarchyList,
        domain_uuid: sessionStorage.getItem('domain_uuid'),
      };

      let response = await commonPostAPI("editdepartment", requestparams);
      if (response.name && response.name !== "") {
        this.setState({ alertMessage: <div>Name already exists.</div> });
      } else {
        this.onOpenAction("departments");
      }
    } else {
      this.setState({
        alertMessage: <div>Please enter required parameters.</div>,
      });
    }
  };

  onOpenAction = (action) => {
    this.props.navigate(`/${action}`);
  };

  onChangeItemAccess = async (e, sub_module_id, access_value) => {
    let isChecked = e.target.checked;

    let { accessHierarchyList } = this.state;
    let temp = accessHierarchyList;
    temp = temp.map((element) => {
      if (element.sub_module_id === sub_module_id && isChecked) {
        let newValue = element.check_access_value + access_value;
        return {
          module_list_id: element.module_list_id,
          sub_module_id: element.sub_module_id,
          access_value: element.access_value,
          name: element.name,
          check_access_value: newValue
        }
      } else if (element.sub_module_id === sub_module_id) {
        let newValue = (element.check_access_value === 0) ? 0 : element.check_access_value - access_value;
        return {
          module_list_id: element.module_list_id,
          sub_module_id: element.sub_module_id,
          access_value: element.access_value,
          name: element.name,
          check_access_value: newValue
        }
      } else {
        return element;
      }
    });

    this.setState({ accessHierarchyList: temp });
  }

  handleTabChange = (value) => {
    let { accessHierarchyList } = this.state;
    const acesssHierarchyHtml = accessHierarchyList.map((element) => (
      (element.module_list_id === value) ?
        <Grid container spacing={5} xs={12}>
          <Grid item md={4}>{element.name}</Grid>
          <Grid item md={1.5}>
            {((element.access_value & AppConfig.VIEW_ACTION) === AppConfig.VIEW_ACTION) ?
              <FormControlLabel
                key={"VIEW-" + element.sub_module_id}
                name={"VIEW-" + element.sub_module_id}
                id={"VIEW-" + element.sub_module_id}
                value={AppConfig.VIEW_ACTION}
                control={((element.check_access_value & AppConfig.VIEW_ACTION) === AppConfig.VIEW_ACTION) ? <Checkbox defaultChecked /> : <Checkbox />}
                onChange={e => this.onChangeItemAccess(e, element.sub_module_id, AppConfig.VIEW_ACTION)}
                sx={{ backgroundColor: 'ff7373' }}
              /> : ""}
          </Grid>
          <Grid item md={1.5}>
            {((element.access_value & AppConfig.ADD_ACTION) === AppConfig.ADD_ACTION) ?
              <FormControlLabel
                key={"ADD-" + element.sub_module_id}
                name={"ADD-" + element.sub_module_id}
                id={"ADD-" + element.sub_module_id}
                value={AppConfig.ADD_ACTION}
                control={((element.check_access_value & AppConfig.ADD_ACTION) === AppConfig.ADD_ACTION) ? <Checkbox defaultChecked /> : <Checkbox />}
                onChange={e => this.onChangeItemAccess(e, element.sub_module_id, AppConfig.ADD_ACTION)}
                sx={{ backgroundColor: 'ff7373' }}
              /> : ""}
          </Grid>
          <Grid item md={1.5}>
            {((element.access_value & AppConfig.EDIT_ACTION) === AppConfig.EDIT_ACTION) ?
              <FormControlLabel
                key={"EDIT-" + element.sub_module_id}
                name={"EDIT-" + element.sub_module_id}
                id={"EDIT-" + element.sub_module_id}
                value={AppConfig.EDIT_ACTION}
                control={((element.check_access_value & AppConfig.EDIT_ACTION) === AppConfig.EDIT_ACTION) ? <Checkbox defaultChecked /> : <Checkbox />}
                onChange={e => this.onChangeItemAccess(e, element.sub_module_id, AppConfig.EDIT_ACTION)}
                sx={{ backgroundColor: 'ff7373' }} />
              : ""}
          </Grid>
          <Grid item md={1.5}>
            {((element.access_value & AppConfig.DELETE_ACTION) === AppConfig.DELETE_ACTION) ?
              <FormControlLabel
                key={"DELETE-" + element.sub_module_id}
                name={"DELETE-" + element.sub_module_id}
                id={"DELETE-" + element.sub_module_id}
                value={AppConfig.DELETE_ACTION}
                control={((element.check_access_value & AppConfig.DELETE_ACTION) === AppConfig.DELETE_ACTION) ? <Checkbox defaultChecked /> : <Checkbox />}
                onChange={e => this.onChangeItemAccess(e, element.sub_module_id, AppConfig.DELETE_ACTION)}
                sx={{ backgroundColor: 'ff7373' }} />
              : ""}
          </Grid>
          <Grid item md={1.5}>
            {((element.access_value & AppConfig.IMPORT_ACTION) === AppConfig.IMPORT_ACTION) ?
              <FormControlLabel
                key={"IMPORT-" + element.sub_module_id}
                name={"IMPORT-" + element.sub_module_id}
                id={"IMPORT-" + element.sub_module_id}
                value={AppConfig.IMPORT_ACTION}
                control={((element.check_access_value & AppConfig.IMPORT_ACTION) === AppConfig.IMPORT_ACTION) ? <Checkbox defaultChecked /> : <Checkbox />}
                onChange={e => this.onChangeItemAccess(e, element.sub_module_id, AppConfig.IMPORT_ACTION)}
                sx={{ backgroundColor: 'ff7373' }} /> : ""}
          </Grid>
        </Grid>
        : ""
    ));


    this.setState({ currentStep: value, acesssHierarchyHtml: acesssHierarchyHtml });
  }

  onChangeItem = async (e) => {
    e.preventDefault();
    partialState[e.target.name] = e.target.value;
    let errors = this.state.errors;
    switch (e.target.name) {
      case "name":
        errors.name =
          e.target.value.length < 1 ? "Please enter name." : "";
        break;
      default:
        break;
    }
    this.setState({ errors, [e.target.name]: e.target.value });
    this.setState(partialState);
  };

  render() {
    if (sessionStorage.length === 0) {
      return <Navigate to="/" replace={true} />;
    }
    const theme = createTheme({
      palette: {
        primary: {
          main: "#0052cc",
        },
        secondary: {
          main: "#edf2ff",
        },
      },
    });

    let {
      errors,
      currentStep,
      alertMessage,
      moduleList,
      acesssHierarchyHtml,
    } = this.state;

    return (
      <div className="submit-form formElements">
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xl">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "15px 0",
              }}
            >
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Custombreadcrumb hierarchy={this.state.hierarchy} />
                </Grid>
              </Grid>
              <Box className="formBox" autoComplete="none">
                <Box
                  component="form"
                  autoComplete="none"
                  onSubmit={this.saveData}
                  noValidate
                  sx={{ mt: 1, width: "100%", padding: "0 15px" }}
                >
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={4}>
                      <label>Name</label>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        name="name"
                        value={this.state.name ?? ""}
                        onChange={this.onChangeItem}
                        autoComplete="none"
                        type="text"
                        size="small"
                      />
                      {errors.name.length > 0 ? (
                        <Stack className="error-show" spacing={2}>
                          {errors.name}
                        </Stack>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item md={4}>
                      <label>Description</label>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="descriptions"
                        name="descriptions"
                        value={this.state.descriptions ?? ""}
                        onChange={this.onChangeItem}
                        autoComplete="none"
                        type="text"
                        size="small"
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={5} xs={12}>
                    <Grid item md={12}></Grid>
                  </Grid>

                  <Grid container spacing={5} xs={12}>
                    <Grid item md={12}>
                      <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext
                          value={currentStep}
                          variant="scrollable"
                          scrollButtons="auto"
                          aria-label="scrollable auto tabs example"
                          orientation="vertical"
                        >
                          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>

                            <TabList>
                              {moduleList.map((item) => {
                                return (
                                  <MenuItem key={item.module_list_id} value={item.module_list_id}>
                                    <Tab label={item.name} onClick={() => this.handleTabChange(item.module_list_id)} />
                                  </MenuItem>
                                );
                              })}
                            </TabList>

                          </Box>
                          {moduleList.map((item) => {
                            return (
                              (currentStep === item.module_list_id) ?
                                <TabPanel value={currentStep}>
                                  <Grid container spacing={5} xs={12}>
                                    <Grid item md={4}><b>Sub Module Name</b></Grid>
                                    <Grid item md={1.5}><b>View</b></Grid>
                                    <Grid item md={1.5}><b>Add</b></Grid>
                                    <Grid item md={1.5}><b>Edit</b></Grid>
                                    <Grid item md={1.5}><b>Remove</b></Grid>
                                    <Grid item md={1.5}><b>Import</b></Grid>
                                  </Grid>
                                  <Grid container spacing={5} xs={12}><Grid item md={12}>&nbsp;</Grid></Grid>
                                  {acesssHierarchyHtml}
                                </TabPanel>
                                :
                                ""
                            );
                          })}
                        </TabContext>
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid container spacing={5} xs={12}>
                    <Grid item md={4}></Grid>
                  </Grid>

                  {alertMessage ? (
                    <Stack
                      className="error-show"
                      sx={{ width: "100%" }}
                      spacing={2}
                    >
                      {alertMessage}
                    </Stack>
                  ) : (
                    ""
                  )}
                  <Grid container justifyContent="flex-start">
                    <table className="form-btns">
                      <tr>
                        <td>
                          <Button
                            type="submit"
                            fullWidth
                            className="btn btn-primary"
                            sx={{
                              mt: 1,
                              mb: 1,
                              height: "35px",
                              padding: "5px 20px",
                            }}
                            size="small"
                          >
                            Submit
                          </Button>
                        </td>
                        <td>
                          <Button
                            type="button"
                            fullWidth
                            className="btn btn-outline-white"
                            sx={{
                              mt: 1,
                              mb: 1,
                              height: "35px",
                              padding: "5px 20px",
                              marginLeft: "10px",
                            }}
                            size="small"
                            onClick={() => this.onOpenAction("departments")}
                          >
                            Back
                          </Button>
                        </td>
                      </tr>
                    </table>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      </div >
    );
  }
}

export default withRouter(DepartmenteditData);
