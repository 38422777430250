import React, { Component } from "react";
import { commonPostAPI } from "../../commoncall/commoncall";
import { Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Custombreadcrumb from "../Custombreadcrumb";
import { Button, Grid, Select, MenuItem } from "@mui/material";
import { withRouter } from "../withRouter";
import AppConfig from "../../Constants/AppConfig";

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};

var partialState = {};
class UseraddData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {
        username: "Please enter Username.",
        email: "Please enter Email.",
        password: "Please enter Password.",
        userrole: "Please select user role.",
        department_id: "Please select department.",
        extension: "",
      },
      alertMessage: "",
      username: "",
      email: "",
      password: "",
      extention_flag: '0',
      department_id: "0",
      extension: "",
      outbound_caller_id_number: "",
      status: true,
      forward_all_destination_action: "0",
      forward_all_destination_action_entry: "0",
      forward_busy_destination_action: "0",
      forward_busy_destination_action_entry: "0",
      forward_no_answer_destination_action: "0",
      forward_no_answer_destination_action_entry: "0",
      forward_user_not_registered_destination_action: "0",
      forward_user_not_registered_destination_action_entry: "0",
      forwardDestinationList: [],
      busyDestinationList: [],
      answerDestinationList: [],
      registerDestinationList: [],
      departmentlist: [],
      userrole: "",
      domainlist: [],
      domain_id: "0",
      hierarchy: [
        {
          label: "Home",
          action: "",
        },
        {
          label: "User",
          action: "user",
        },
        {
          label: "Add User",
          action: "useradd",
        },
      ],
    };
  }

  componentDidMount = async () => {
    let departmentlist = await commonPostAPI("departmentlist", { domain_uuid: sessionStorage.getItem('domain_uuid'), department_id: sessionStorage.getItem('department_id') });
    this.setState({ departmentlist: departmentlist });

    let domainlist = await commonPostAPI("domainlists", {});
    this.setState({ domainlist: domainlist });
  }

  saveData = async (event) => {
    event.preventDefault();
    if (validateForm(this.state.errors)) {
      this.setState({ alertMessage: "" });
      let requestparams = {
        username: this.state.username,
        email: this.state.email,
        password: this.state.password,
        outbound_caller_id_number: this.state.outbound_caller_id_number,
        status: this.state.status,
        extension: this.state.extension,
        extention_flag: this.state.extention_flag,
        userrole: this.state.userrole,
        domain_uuid: (this.state.domain_id && this.state.domain_id !== '0') ? this.state.domain_id : sessionStorage.getItem("domain_uuid"),
        domain_name: sessionStorage.getItem("domain_name"),
        department_id: this.state.department_id,
        domain_id: this.state.domain_id,
        forward_all_destination_action_entry:
          this.state.forward_all_destination_action_entry,
        forward_busy_destination_action_entry:
          this.state.forward_busy_destination_action_entry,
        forward_no_answer_destination_action_entry:
          this.state.forward_no_answer_destination_action_entry,
        forward_user_not_registered_destination_action_entry:
          this.state.forward_user_not_registered_destination_action_entry,
        forward_all_destination_action:
          this.state.forward_all_destination_action,
        forward_busy_destination_action:
          this.state.forward_busy_destination_action,
        forward_no_answer_destination_action:
          this.state.forward_no_answer_destination_action,
        forward_user_not_registered_destination_action:
          this.state.forward_user_not_registered_destination_action,
      };

      let response = await commonPostAPI("adduser", requestparams);
      if (response.username && response.username !== "") {
        this.setState({ alertMessage: <div>Username already exists.</div> });
      } else if (response.extension && response.extension !== "") {
        this.setState({ alertMessage: <div>Extension already exists.</div> });
      } else {
        this.onOpenAction("user");
      }
    } else {
      this.setState({
        alertMessage: <div>Please enter required parameters.</div>,
      });
    }
  };

  onOpenAction = (action) => {
    this.props.navigate(`/${action}`);
  };

  onChangeItem = async (e) => {
    e.preventDefault();
    partialState[e.target.name] = e.target.value;
    if (e.target.name === "forward_all_destination_action") {
      if (e.target.value !== '6') {
        await this.timeOutActionForwardingChange(e, "1");
      } else {
        this.setState({ forward_all_destination_action_entry: "" });
      }
    }
    if (e.target.name === "forward_busy_destination_action") {
      await this.timeOutActionForwardingChange(e, "2");
    }
    if (e.target.name === "forward_no_answer_destination_action") {
      await this.timeOutActionForwardingChange(e, "3");
    }
    if (e.target.name === "forward_user_not_registered_destination_action") {
      await this.timeOutActionForwardingChange(e, "4");
    }

    let errors = this.state.errors;
    switch (e.target.name) {
      case "username":
        errors.username =
          e.target.value.length < 1 ? "Please enter username." : "";
        break;
      case "password":
        errors.password =
          e.target.value.length < 1 ? "Please enter password." : "";
        break;
      case "email":
        errors.email = e.target.value.length < 1 ? "Please enter email." : "";
        break;
      case "status":
        errors.status =
          e.target.value.length < 1 ? "Please select Status." : "";
        break;
      case "userrole":
        errors.userrole =
          e.target.value.length < 1 ? "Please select User Role." : "";
        break;
      case "extention_flag":
        errors.extension =
          (e.target.value === '1' && this.state.extension === "") ? "Please enter extension." : "";
        break;
      case "extension":
        errors.extension =
          (this.state.extention_flag === '1' && e.target.value.length < 1) ? "Please enter extension." : "";
        break;
      case "department_id":
        errors.department_id =
          e.target.value.length < 1 ? "Please select department." : "";
        break;
      default:
        break;
    }
    this.setState({ errors, [e.target.name]: e.target.value });
    this.setState(partialState);
  };

  timeOutActionForwardingChange = async (e, method) => {
    const value = e.target.value;
    const domain_uuid = sessionStorage.getItem("domain_uuid");
    const domain_name = sessionStorage.getItem("domain_name");
    let response = await commonPostAPI("callactionforwarding", {
      type: value,
      domain_uuid: domain_uuid,
      domain_name: domain_name,
    });
    switch (method) {
      case "1":
        this.setState({
          forwardDestinationList: response,
          forward_all_destination_action_entry: "0",
        });
        break;
      case "2":
        this.setState({
          busyDestinationList: response,
          forward_busy_destination_action_entry: "0",
        });
        break;
      case "3":
        this.setState({
          answerDestinationList: response,
          forward_no_answer_destination_action_entry: "0",
        });
        break;
      case "4":
        this.setState({
          registerDestinationList: response,
          forward_user_not_registered_destination_action_entry: "0",
        });
        break;
      default:
        break;
    }
  };

  render() {
    if (sessionStorage.length === 0) {
      return <Navigate to="/" replace={true} />;
    }
    const theme = createTheme({
      palette: {
        primary: {
          main: "#0052cc",
        },
        secondary: {
          main: "#edf2ff",
        },
      },
    });
    let {
      errors,
      username,
      password,
      email,
      outbound_caller_id_number,
      alertMessage,
      status,
      forward_all_destination_action,
      forward_all_destination_action_entry,
      forward_busy_destination_action,
      forward_busy_destination_action_entry,
      forward_no_answer_destination_action,
      forward_no_answer_destination_action_entry,
      forward_user_not_registered_destination_action,
      forward_user_not_registered_destination_action_entry,
      forwardDestinationList,
      busyDestinationList,
      answerDestinationList,
      registerDestinationList,
      extention_flag,
      extension,
      userrole,
      department_id,
      departmentlist,
      domainlist,
      domain_id,
    } = this.state;
    return (
      <div className="submit-form formElements">
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xl">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "15px 0",
              }}
            >
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Custombreadcrumb hierarchy={this.state.hierarchy} />
                </Grid>
              </Grid>
              <Box className="formBox" autoComplete="none">
                <Box
                  component="form"
                  autoComplete="none"
                  onSubmit={this.saveData}
                  noValidate
                  sx={{ mt: 1, width: "100%", padding: "0 15px" }}
                >
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={3}>
                      <label>User Name</label>
                      <TextField
                        margin="normal"
                        required
                        id="username"
                        name="username"
                        value={username ?? ""}
                        onChange={this.onChangeItem}
                        autoComplete="none"
                        type="text"
                        size="small"
                        fullWidth
                      />
                      {errors.username.length > 0 ? (
                        <Stack
                          className="error-show"
                          sx={{ width: "100%" }}
                          spacing={2}
                        >
                          {errors.username}
                        </Stack>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item md={3}>
                      <label>Password</label>
                      <TextField
                        margin="normal"
                        required
                        id="password"
                        name="password"
                        value={password ?? ""}
                        onChange={this.onChangeItem}
                        autoComplete="none"
                        type="password"
                        size="small"
                        fullWidth
                      />
                      {errors.password.length > 0 ? (
                        <Stack
                          className="error-show"
                          sx={{ width: "100%" }}
                          spacing={2}
                        >
                          {errors.password}
                        </Stack>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item md={3}>
                      <label>Email</label>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="email"
                        type="text"
                        id="Email"
                        value={email ?? ""}
                        onChange={this.onChangeItem}
                        autoComplete="none"
                        size="small"
                      />
                      {errors.email.length > 0 ? (
                        <Stack
                          className="error-show"
                          sx={{ width: "100%" }}
                          spacing={2}
                        >
                          {errors.email}
                        </Stack>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item md={3}>
                      <label>Role</label>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Select
                            fullWidth
                            labelId="userrole"
                            id="userrole"
                            value={
                              userrole
                            }
                            label="userrole"
                            name="userrole"
                            onChange={this.onChangeItem}
                            size="small"
                            autoComplete="none"
                          >
                            <MenuItem value="">Select</MenuItem>
                            <MenuItem value="1">Admin</MenuItem>
                            <MenuItem value="3">Agent</MenuItem>
                            <MenuItem value="4">User</MenuItem>
                          </Select>
                          {errors.userrole.length > 0 ? (
                            <Stack
                              className="error-show"
                              sx={{ width: "100%" }}
                              spacing={2}
                            >
                              {errors.userrole}
                            </Stack>
                          ) : (
                            ""
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container spacing={5} xs={12}>
                    <Grid item md={3}>
                      <label>Outbound Caller ID</label>
                      <TextField
                        margin="normal"
                        name="outbound_caller_id_number"
                        type="text"
                        id="outbound_caller_id_number"
                        value={outbound_caller_id_number ?? ""}
                        onChange={this.onChangeItem}
                        autoComplete="none"
                        size="small"
                      />
                    </Grid>
                    <Grid item md={3}>
                      <label>
                        Status
                      </label>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Select
                            fullWidth
                            id="status"
                            value={status}
                            label="Status"
                            name="status"
                            onChange={this.onChangeItem}
                            autoComplete="none"
                          >
                            <MenuItem value="true">Active</MenuItem>
                            <MenuItem value="false">In Active</MenuItem>
                          </Select>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={3}>
                      <label>Set Manual extension</label>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Select
                            fullWidth
                            id="extention_flag"
                            value={extention_flag}
                            label="extention_flag"
                            name="extention_flag"
                            onChange={this.onChangeItem}
                            autoComplete="none"
                          >
                            <MenuItem value="1">Yes</MenuItem>
                            <MenuItem value="0">No</MenuItem>
                          </Select>
                        </Grid>
                      </Grid>

                    </Grid>
                    <Grid item md={3}>
                      <label>Extension</label>
                      <TextField
                        fullWidth
                        margin="normal"
                        name="extension"
                        type="number"
                        id="extension"
                        value={extension ?? ""}
                        onChange={this.onChangeItem}
                        autoComplete="none"
                        size="small"
                        required={extention_flag === "1" ? "required" : ""}
                        disabled={extention_flag === "1" ? "" : "disabled"}
                      />
                      {errors.extension.length > 0 ? (
                        <Stack
                          className="error-show"
                          sx={{ width: "100%" }}
                          spacing={2}
                        >
                          {errors.extension}
                        </Stack>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>

                  <Grid container spacing={5} xs={12}>
                    <Grid item md={6}>
                      <label >Call Forward</label>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Select
                            fullWidth
                            labelId="forward_all_destination_action"
                            id="forward_all_destination_action"
                            value={forward_all_destination_action}
                            label="forward_all_destination_action"
                            name="forward_all_destination_action"
                            onChange={this.onChangeItem}
                            size="small"
                            autoComplete="none"
                          >
                            <MenuItem value="0">Select</MenuItem>
                            {AppConfig.VOICEMAIL_ENABLE === true ? (<MenuItem value="1">Voicemail</MenuItem>) : ""}
                            <MenuItem value="2">User</MenuItem>
                            <MenuItem value="3">Ringgroup</MenuItem>
                            <MenuItem value="4">IVR Menu</MenuItem>
                            <MenuItem value="5">Callcenter Queue</MenuItem>
                            <MenuItem value="6">Custom</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={6}>
                          {
                            (forward_all_destination_action === '6') ?
                              <TextField
                                margin="normal"
                                required
                                id="forward_all_destination_action_entry"
                                name="forward_all_destination_action_entry"
                                value={forward_all_destination_action_entry ?? ""}
                                onChange={this.onChangeItem}
                                autoComplete="none"
                                type="text"
                                size="small"
                              />
                              :
                              <Select
                                fullWidth
                                labelId="forward_all_destination_action_entry"
                                id="forward_all_destination_action_entry"
                                value={forward_all_destination_action_entry}
                                label="forward_all_destination_action_entry"
                                name="forward_all_destination_action_entry"
                                onChange={this.onChangeItem}
                                size="small"
                                autoComplete="none"
                              >
                                <MenuItem value="0">Select</MenuItem>
                                {forwardDestinationList.map((item) => {
                                  return (
                                    <MenuItem key={item.value} value={item.value}>
                                      {item.label}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                          }

                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={6}>
                      <label>On Busy</label>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Select
                            fullWidth
                            labelId="forward_busy_destination_action"
                            id="forward_busy_destination_action"
                            value={forward_busy_destination_action}
                            label="forward_busy_destination_action"
                            name="forward_busy_destination_action"
                            onChange={this.onChangeItem}
                            size="small"
                            autoComplete="none"
                          >
                            <MenuItem value="0">Select</MenuItem>
                            {AppConfig.VOICEMAIL_ENABLE === true ? (<MenuItem value="1">Voicemail</MenuItem>) : ""}
                            <MenuItem value="2">User</MenuItem>
                            <MenuItem value="3">Ringgroup</MenuItem>
                            <MenuItem value="4">IVR Menu</MenuItem>
                            <MenuItem value="5">Callcenter Queue</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={6}>
                          <Select
                            fullWidth
                            labelId="forward_busy_destination_action_entry"
                            id="forward_busy_destination_action_entry"
                            value={forward_busy_destination_action_entry}
                            label="forward_busy_destination_action_entry"
                            name="forward_busy_destination_action_entry"
                            onChange={this.onChangeItem}
                            size="small"
                            autoComplete="none"
                          >
                            <MenuItem value="0">Select</MenuItem>
                            {busyDestinationList.map((item) => {
                              return (
                                <MenuItem key={item.value} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={6}>
                      <label>No Answer</label>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Select
                            fullWidth
                            labelId="forward_no_answer_destination_action"
                            id="forward_no_answer_destination_action"
                            value={forward_no_answer_destination_action}
                            label="forward_no_answer_destination_action"
                            name="forward_no_answer_destination_action"
                            onChange={this.onChangeItem}
                            size="small"
                            autoComplete="none"
                          >
                            <MenuItem value="0">Select</MenuItem>
                            {AppConfig.VOICEMAIL_ENABLE === true ? (<MenuItem value="1">Voicemail</MenuItem>) : ""}
                            <MenuItem value="2">User</MenuItem>
                            <MenuItem value="3">Ringgroup</MenuItem>
                            <MenuItem value="4">IVR Menu</MenuItem>
                            <MenuItem value="5">Callcenter Queue</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={6}>
                          <Select
                            fullWidth
                            labelId="forward_no_answer_destination_action_entry"
                            id="forward_no_answer_destination_action_entry"
                            value={forward_no_answer_destination_action_entry}
                            label="forward_no_answer_destination_action_entry"
                            name="forward_no_answer_destination_action_entry"
                            onChange={this.onChangeItem}
                            size="small"
                            autoComplete="none"
                          >
                            <MenuItem value="0">Select</MenuItem>
                            {answerDestinationList.map((item) => {
                              return (
                                <MenuItem key={item.value} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={6}>
                      <label>Not Registered</label>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Select
                            fullWidth
                            labelId="forward_user_not_registered_destination_action"
                            id="forward_user_not_registered_destination_action"
                            value={
                              forward_user_not_registered_destination_action
                            }
                            label="forward_user_not_registered_destination_action"
                            name="forward_user_not_registered_destination_action"
                            onChange={this.onChangeItem}
                            size="small"
                            autoComplete="none"
                          >
                            <MenuItem value="0">Select</MenuItem>
                            {AppConfig.VOICEMAIL_ENABLE === true ? (<MenuItem value="1">Voicemail</MenuItem>) : ""}
                            <MenuItem value="2">User</MenuItem>
                            <MenuItem value="3">Ringgroup</MenuItem>
                            <MenuItem value="4">IVR Menu</MenuItem>
                            <MenuItem value="5">Callcenter Queue</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={6}>
                          <Select
                            fullWidth
                            labelId="forward_user_not_registered_destination_action_entry"
                            id="forward_user_not_registered_destination_action_entry"
                            value={
                              forward_user_not_registered_destination_action_entry
                            }
                            label="forward_user_not_registered_destination_action_entry"
                            name="forward_user_not_registered_destination_action_entry"
                            onChange={this.onChangeItem}
                            size="small"
                            autoComplete="none"
                          >
                            <MenuItem value="0">Select</MenuItem>
                            {registerDestinationList.map((item) => {
                              return (
                                <MenuItem key={item.value} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </Grid>
                      </Grid>
                    </Grid>

                  </Grid>
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={3}>
                      <label>Department</label>
                      <Select
                        labelId="department_id"
                        id="department_id"
                        value={department_id}
                        label="department_id"
                        name="department_id"
                        onChange={this.onChangeItem}
                        size="small"
                        autoComplete="none"
                        fullWidth
                      >
                        <MenuItem value="0">Select</MenuItem>
                        {departmentlist.map((item) => {
                          return (
                            <MenuItem key={item.department_id} value={item.department_id}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Grid>

                    <Grid item md={3}>
                      <label>Domain</label>
                      <Select
                        fullWidth
                        labelId="domain_id"
                        id="domain_id"
                        value={domain_id}
                        label="domain_id"
                        name="domain_id"
                        onChange={this.onChangeItem}
                        size="small"
                        autoComplete="none"
                      >
                        <MenuItem value="0">Select</MenuItem>
                        {domainlist.map((item) => {
                          return (
                            <MenuItem key={item.domain_uuid} value={item.domain_uuid}>
                              {item.domain_name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Grid>
                  </Grid>

                  {alertMessage ? (
                    <Stack
                      className="error-show"
                      sx={{ width: "100%" }}
                      spacing={2}
                    >
                      {alertMessage}
                    </Stack>
                  ) : (
                    ""
                  )}
                  <Grid container justifyContent="flex-end">
                    <table className="form-btns">
                      <tr>
                        <td>
                          <Button
                            type="submit"
                            fullWidth
                            className="btn btn-primary"
                            sx={{
                              mt: 1,
                              mb: 1,
                              height: "35px",
                              padding: "5px 20px",
                            }}
                            size="small"
                          >
                            Submit
                          </Button>
                        </td>
                        <td>
                          <Button
                            type="button"
                            fullWidth
                            className="btn btn-outline-white"
                            sx={{
                              mt: 1,
                              mb: 1,
                              height: "35px",
                              padding: "5px 20px",
                              marginLeft: "10px",
                            }}
                            size="small"
                            onClick={() => this.onOpenAction("user")}
                          >
                            Back
                          </Button>
                        </td>
                      </tr>
                    </table>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      </div >
    );
  }
}

export default withRouter(UseraddData);
