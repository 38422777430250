
const AppConfig = {
	apiUrl: 'https://oc.breelink.com:4002/api/',
    LOG_FILE: 'tmp/nodelog.log',
    webSocketUrl: 'wss://oc.breelink.com:4002',
    REACT_APP_WSS_URL: 'wss://oc.breelink.com:7443',
    REACT_APP_AGENT_PORT: ':543',
    REACT_APP_PHONE_ENABLE: '1',
    VOICEMAIL_ENABLE: true,
    CONFERENCE_ENABLE: true,
    PBX_ONLY: false,
    VIEW_ACTION: 1,
    ADD_ACTION: 2,
    EDIT_ACTION: 4,
    DELETE_ACTION: 8,
    IMPORT_ACTION: 16,
}
export default AppConfig;
