import React, { Component } from "react";
import { commonPostAPI } from "../../commoncall/commoncall";
import { Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Custombreadcrumb from "../Custombreadcrumb";
import { Button, Grid, Select, MenuItem } from "@mui/material";
import { withRouter } from "../withRouter";
import AppConfig from "../../Constants/AppConfig";

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};

var partialState = {
  did_number: "",
  did_timeout: "",
  did_timeout_entry: "",
  domain_uuid: "",
  domain_name: "",
  did_uuid: "",
};

class DideditData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {
        did_number: "Please enter name.",
        did_timeout: "",
        did_timeout_entry: "",
        dialplan_uuid: "",
      },
      alertMessage: "",
      did_number: "",
      did_timeout: "1",
      did_timeout_entry: "hangup:",
      did_uuid: "",
      dialplan_uuid: "",
      timeOutEntryList: [{ value: "hangup:", label: "hangup" }],
      hierarchy: [
        {
          label: "Home",
          action: "",
        },
        {
          label: "Did",
          action: "did",
        },
        {
          label: "Edit Did",
          action: "didedit",
        },
      ],
    };
  }

  timeOutActionChange = async (e) => {
    const value = e.target.value;
    const domain_uuid = sessionStorage.getItem("domain_uuid");
    const domain_name = sessionStorage.getItem("domain_name");
    if (value !== "1") {
      let response = await commonPostAPI("callaction", {
        type: value,
        domain_uuid: domain_uuid,
        domain_name: domain_name,
      });
      this.setState({ timeOutEntryList: response, did_timeout_entry: "0" });
    } else {
      this.setState({
        timeOutEntryList: [{ value: "hangup:", label: "hangup" }],
        did_timeout_entry: "0",
      });
    }
  };

  componentDidMount = async () => {
    var url = window.location.pathname;
    var id = url.substring(url.lastIndexOf("/") + 1);
    this.getDataByID(id);
  };

  saveData = async (event) => {
    event.preventDefault();
    if (validateForm(this.state.errors)) {
      this.setState({ alertMessage: "" });

      let requestparams = {
        did_uuid: this.state.did_uuid,
        did_number: this.state.did_number,
        did_timeout: this.state.did_timeout,
        did_timeout_entry: this.state.did_timeout_entry,
        domain_uuid: sessionStorage.getItem("domain_uuid"),
        domain_name: sessionStorage.getItem("domain_name"),
        dialplan_uuid: this.state.dialplan_uuid,
      };
      if (
        this.state.did_timeout !== "1" &&
        this.state.did_timeout_entry === "0"
      ) {
        this.setState({ alertMessage: <div>Please select entry.</div> });
      } else {
        let response = await commonPostAPI("updatedid", requestparams);
        if (response.did_number && response.did_number !== "") {
          this.setState({ alertMessage: <div>Number already exists.</div> });
        } else {
          this.onOpenAction("did");
        }
      }
    } else {
      this.setState({
        alertMessage: <div>Please enter required parameters.</div>,
      });
    }
  };

  onChangeItem = (e) => {
    e.preventDefault();
    partialState[e.target.name] = e.target.value;
    if (e.target.name === "did_timeout") {
      this.timeOutActionChange(e);
    }
    let errors = this.state.errors;
    switch (e.target.name) {
      case "did_number":
        errors.did_number =
          e.target.value.length < 1 ? "Please enter number." : "";
        break;
      case "did_timeout":
        errors.did_timeout =
          e.target.value.length < 1 ? "Please select timeout Action." : "";
        break;
      case "did_timeout_entry":
        errors.did_timeout_entry =
          e.target.value.length < 1
            ? "Please select timeout action entry."
            : "";
        break;
      default:
        break;
    }

    this.setState({ errors, [e.target.name]: e.target.value });
    this.setState(partialState);
  };

  getDataByID = async (id) => {
    let response = await commonPostAPI("getRecordDidsByID", { id: id });
    let errors = this.state.errors;
    Object.keys(response).forEach(function (key) {
      switch (key) {
        case "did_number":
          errors.did_number =
            typeof response[key] !== "undefined" &&
              response[key] !== "" &&
              response[key] != null
              ? ""
              : "Please enter number.";
          break;
        case "timeout_action_type":
          errors.did_timeout =
            typeof response[key] !== "undefined" &&
              response[key] !== "" &&
              response[key] != null
              ? ""
              : "Please select timeout Action";
          break;
        case "did_timeout_entry":
          errors.did_timeout_entry =
            typeof response[key] !== "undefined" &&
              response[key] !== "" &&
              response[key] != null
              ? ""
              : "Please select timeout action entry.";
          break;
        default:
          break;
      }
    });

    if (response.timeout_action_type !== "1") {
      const domain_uuid = sessionStorage.getItem("domain_uuid");
      const domain_name = sessionStorage.getItem("domain_name");
      let responseList = await commonPostAPI("callaction", {
        type: response.timeout_action_type,
        domain_uuid: domain_uuid,
        domain_name: domain_name,
      });
      this.setState({ timeOutEntryList: responseList });
    }

    var dbData = {
      did_number: response.did_number,
      did_timeout: response.timeout_action_type,
      did_timeout_entry: response.timeout_action_entry,
      did_uuid: response.did_uuid,
      dialplan_uuid: response.dialplan_uuid,
    };

    partialState = dbData;

    this.setState(partialState);
  };

  onOpenAction = (action) => {
    this.props.navigate(`/${action}`);
  };

  render() {
    if (sessionStorage.length === 0) {
      return <Navigate to="/" replace={true} />;
    }

    const theme = createTheme();
    let {
      errors,
      did_number,
      did_timeout,
      alertMessage,
      did_timeout_entry,
      timeOutEntryList,
    } = this.state;

    return (
      <div className="submit-form formElements">
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xl">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "15px 0",
              }}
            >
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Custombreadcrumb hierarchy={this.state.hierarchy} />
                </Grid>
              </Grid>
              <Box className="formBox">
                <Box
                  component="form"
                  onSubmit={this.saveData}
                  noValidate
                  sx={{ mt: 2, width: "100%", padding: "0 15px" }}
                >
                  <Grid container spacing={5} xs={12}>
                    <Grid item md={4}>
                      <label>Number</label>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="did_number"
                        name="did_number"
                        value={did_number ?? ""}
                        onChange={this.onChangeItem}
                        autoComplete="none"
                        type="number"
                        size="small"
                      />
                      {errors.did_number.length > 0 ? (
                        <Stack className="error-show" spacing={2}>
                          {errors.did_number}
                        </Stack>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item md={4}>
                      <label>Action</label>
                      <Grid container spacing={5} xs={12}>
                        <Grid item md={12}>
                          <Select
                            fullWidth
                            labelId="did_timeout"
                            id="did_timeout"
                            value={did_timeout}
                            label="did_timeout"
                            name="did_timeout"
                            onChange={this.onChangeItem}
                            size="small"
                            autoComplete="none"
                          >
                            <MenuItem value="1">End Call</MenuItem>
                            {AppConfig.VOICEMAIL_ENABLE === true ? (<MenuItem value="2">Voicemail</MenuItem>) : ""}
                            <MenuItem value="3">User</MenuItem>
                            <MenuItem value="4">Ringgroup</MenuItem>
                            <MenuItem value="5">IVR Menu</MenuItem>
                            <MenuItem value="6">Callcenter Queue</MenuItem>
                            <MenuItem value="7">Recording</MenuItem>
                          </Select>
                        </Grid>
                      </Grid>

                    </Grid>
                    <Grid item md={4}>
                      <label>
                        Entry
                      </label>
                      <Grid container spacing={5} xs={12}>
                        <Grid item md={12}>
                          <Select
                            fullWidth
                            labelId="did_timeout_entry"
                            id="did_timeout_entry"
                            value={did_timeout_entry}
                            label="did_timeout_entry"
                            name="did_timeout_entry"
                            onChange={this.onChangeItem}
                            size="small"
                            autoComplete="none"
                          >
                            <MenuItem value="0">Select</MenuItem>
                            {timeOutEntryList.map((item) => {
                              return (
                                <MenuItem key={item.value} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </Grid>
                      </Grid>

                      {errors.did_timeout_entry.length > 0 ? (
                        <Stack className="error-show" spacing={2}>
                          {errors.did_timeout_entry}
                        </Stack>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={5} xs={12}>
                    <Grid item xs={12}>
                      {alertMessage ? (
                        <Stack className="error-show" spacing={2}>
                          {alertMessage}
                        </Stack>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="flex-end" xs={12}>
                    <table className="form-btns">
                      <tr>
                        <td>
                          <Button
                            type="submit"
                            fullWidth
                            className="btn btn-primary"
                            sx={{
                              mt: 1,
                              mb: 1,
                              height: "35px",
                              padding: "5px 20px",
                              marginLeft: "10px",
                            }}
                            size="small"
                          >
                            Submit
                          </Button>
                        </td>
                        <td>
                          <Button
                            type="button"
                            fullWidth
                            className="btn btn-outline-white"
                            sx={{
                              mt: 1,
                              mb: 1,
                              height: "35px",
                              padding: "5px 20px",
                              marginLeft: "10px",
                            }}
                            size="small"
                            onClick={() => this.onOpenAction("did")}
                          >
                            Back
                          </Button>
                        </td>
                      </tr>
                    </table>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      </div >
    );
  }
}

export default withRouter(DideditData);
